import React, { useState } from 'react'
import styles from './Login.module.css'

// ROUTER
import { useNavigate } from 'react-router-dom'

// REDUX
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../../redux/user/user.action'

// COMPONENTS
import { ReactComponent as LogoMindoWhite } from '../../assets/images/logo/logo-mindo--white.svg'
import { Form, Input, Button, message } from 'antd'

import './Login.scss'
import axios from 'axios'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = (values) => {
    setLoading(true)
    const { username, password } = values
    const data = JSON.stringify({
      username,
      password,
    })
    axios({
      method: 'post',
      url: '/login',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((response) => {
        if (response.data.user.role === 'peserta') {
          message.error('Username atau Password salah')
        } else {
          dispatch(setCurrentUser(response.data))
          form.resetFields()
          message.success('Login Berhasil')
          navigate('/')
        }
        setLoading(false)
      })
      .catch((error) => {
        if (error.response === undefined) {
          message.error('Akun anda diblokir')
        } else {
          message.error('Username atau Password salah')
        }
        setLoading(false)
      })
  }

  return (
    <div id="login-page">
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.header}>
            <LogoMindoWhite />
          </div>
          <div className={styles.content}>
            <p>Masuk Ke Dashboard Admin</p>
            <Form
              form={form}
              name="loginForm"
              onFinish={onFinish}
              initialValues={{
                size: 'large',
              }}>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'username belum di isi!',
                  },
                ]}>
                <Input placeholder="username" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'password belum di isi!!',
                  },
                ]}>
                <Input.Password placeholder="password" />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  block
                  className={styles.btnSubmit}>
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
