import React, { useEffect, useState } from 'react'
import styles from './ProgramForm.module.css'
import './ProgramForm.scss'
import axios from 'axios'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import { removeCurrentUser } from '../../../../redux/user/user.action'

// COMPONENTS
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Rate,
  Select,
  Space,
  Spin,
  Switch,
  Upload,
} from 'antd'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
const { Option } = Select

const { RangePicker } = DatePicker

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} harus diisi!',
}
/* eslint-enable no-template-curly-in-string */

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

const ProgramForm = ({ initData = null, title = '' }) => {
  const currentUser = useSelector(selectCurrentUser)
  const [programFormdependency, setProgramFormdependency] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [syllabus, setSyllabus] = useState('')
  const [description, setDescription] = useState('')
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const navigate = useNavigate()

  // Upload Form
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)
  const [cover, setCover] = useState(null)
  // const [theoryFile, setTheoryFile] = useState(null)
  // const [workshop, setWorkshop] = useState(null)

  const uploadButton = (
    <div style={{ margin: 50 }}>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const handleRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      setCover(info.file.originFileObj)
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImageUrl(imageUrl),
        setLoading(false)
      )
    }
  }

  useEffect(() => {
    axios({
      method: 'get',
      url: '/product/create',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then((response) => {
        setProgramFormdependency(response.data)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })

    return () => {}
  }, [currentUser.token, dispatch, navigate])

  const onFinish = (values) => {
    setSubmitLoading(true)
    const {
      name,
      name_pic,
      category_id,
      type,
      type_product,
      trainer_id,
      facility_name,
      duration,
      rating,
      status,
      kuota,
      link_group,
      link_zoom,
      link_campsite,
      link_theory,
      link_workshop,
      price,
      tag_name,
      featured,
    } = values

    console.log(values)

    const time = {
      time_start: duration[0].format('YYYY-MM-DD HH:mm'),
      time_end: duration[1].format('YYYY-MM-DD HH:mm'),
    }

    const FormData = require('form-data')
    const data = new FormData()
    data.append('name', name)
    data.append('description', description)
    data.append('cover', cover)
    data.append('category_id', category_id)
    data.append('trainer_id', trainer_id)
    data.append('type', type)
    data.append('type_product', type_product)
    data.append('time_start', time.time_start)
    data.append('time_end', time.time_end)
    data.append('name_pic', name_pic === '' ? null : name_pic)
    data.append('kuota', kuota)
    data.append('link_theory', link_theory === '' ? null : link_theory)
    data.append('link_workshop', link_workshop === '' ? null : link_workshop)
    data.append('link_zoom', link_zoom === '' ? null : link_zoom)
    data.append('link_group', link_group === '' ? null : link_group)
    data.append('link_campsite', link_campsite === '' ? null : link_campsite)
    data.append('rating', rating)
    data.append('status', status)
    data.append('syllabus', syllabus)
    data.append('price', JSON.stringify(price))
    data.append('facility_name', facility_name)
    data.append('tag_name', tag_name === '' ? null : tag_name)
    data.append('featured', featured === true ? '1' : '0')

    axios({
      method: 'post',
      url: '/product',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
      data: data,
    })
      .then((response) => {
        if (response.data.status === false) {
          message.error(
            response.data.message.name[0]
              ? `Nama program sudah ada`
              : response.data.message.description[0]
              ? `403 Forbidden : ${response.data.message.description[0]} Perhatikan bagian Deskripsi`
              : response.data.message.syllabus[0]
              ? `403 Forbidden : ${response.data.message.syllabus[0]} Perhatikan bagian Silabus`
              : `403 Forbidden : Periksa kembali, pastikan input yang anda masukkan benar`
          )
          setSubmitLoading(false)
        } else {
          form.resetFields()
          message.success('Program Berhasil Ditambah')
          navigate('/program')
        }
      })
      .catch((error) => {
        setSubmitLoading(false)
        if (error.response.data.status === false) {
          message.error(
            error.response.data.message.name[0]
              ? `Nama program sudah ada`
              : error.response.data.message.description[0]
              ? `403 Forbidden : ${error.response.data.message.description[0]} Perhatikan bagian Deskripsi`
              : error.response.data.message.syllabus[0]
              ? `403 Forbidden : ${error.response.data.message.syllabus[0]} Perhatikan bagian Silabus`
              : `403 Forbidden : Periksa kembali, pastikan input yang anda masukkan benar`
          )
        }
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
      })
  }

  console.log(programFormdependency)

  return (
    <div className={styles.container} id="programTambahPage">
      <div className={styles.card}>
        <h1 className={styles.title}>{title}</h1>
        {programFormdependency ? (
          <>
            <Form
              form={form}
              layout={'vertical'}
              onFinish={onFinish}
              initialValues={{
                link_zoom: null,
                name_pic: null,
                link_group: null,
                link_campsite: null,
                link_theory: null,
                link_workshop: null,
                tag_name: null,
              }}
              autoComplete="off"
              validateMessages={validateMessages}>
              <Form.Item
                label="Nama Program"
                name="name"
                rules={[{ required: true }]}>
                <Input placeholder="Nama Program" size="large" />
              </Form.Item>
              <Form.Item label="Nama PIC" name="name_pic">
                <Input placeholder="Nama PIC" size="large" />
              </Form.Item>
              <Form.Item
                name="category_id"
                label="Category Program"
                rules={[{ required: true }]}>
                <Select placeholder="Pilih Category Program">
                  {programFormdependency.category.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                <Select placeholder="Pilih Type">
                  {programFormdependency.type_product.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="type_product"
                label="Type Program"
                rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value="reguler">Reguler</Radio>
                  <Radio value="integrated">Integrated</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="trainer_id"
                label="Trainer"
                rules={[{ required: true }]}>
                <Select placeholder="Pilih Trainer">
                  {programFormdependency.trainer.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="facility_name"
                label="Fasilitas Program"
                rules={[{ required: true }]}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Pilih Fasilitas">
                  {programFormdependency.facility.map((item) => (
                    <Option value={item.name} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Durasi"
                name="duration"
                rules={[{ required: true }]}>
                <RangePicker
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                />
              </Form.Item>
              <Form.Item
                label="Rating"
                name="rating"
                rules={[{ required: true }]}>
                <Rate allowHalf />
              </Form.Item>
              <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true }]}>
                <Select placeholder="Pilih Status">
                  <Option value="active">Active</Option>
                  <Option value="recall">Recall</Option>
                  <Option value="draft">Draft</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Kuota"
                name="kuota"
                rules={[{ required: true }]}>
                <InputNumber
                  style={{ width: 150 }}
                  min={1}
                  placeholder="Jumlah Kuota"
                />
              </Form.Item>
              <Form.Item label="Link Group" name="link_group">
                <Input placeholder="Link Group" size="large" />
              </Form.Item>
              <Form.Item label="Link Zoom" name="link_zoom">
                <Input placeholder="Link Zoom" size="large" />
              </Form.Item>
              <Form.Item label="Link Campsite" name="link_campsite">
                <Input placeholder="Link Campsite" size="large" />
              </Form.Item>
              <Form.Item
                label="Silabus"
                name="syllabus"
                rules={[{ required: true }]}>
                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setSyllabus(data)
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Deskripsi Program"
                name="description"
                rules={[{ required: true }]}>
                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setDescription(data)
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Cover"
                name="cover"
                rules={[{ required: true }]}
                extra="Image (JPG/PNG/JPEG)">
                <Upload
                  accept="image/png, image/jpeg, image/jpg"
                  style={{ width: '100%' }}
                  name="cover"
                  listType="picture-card"
                  className="avatar-uploader"
                  customRequest={handleRequest}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}>
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="cover"
                      style={{ maxHeight: '300px' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <Form.Item label="Link Teori" name="link_theory">
                <Input placeholder="Link Teori" size="large" />
              </Form.Item>
              <Form.Item label="Link Workshop" name="link_workshop">
                <Input placeholder="Link Workshop" size="large" />
              </Form.Item>
              {/* <Form.Item
                label="File Teori"
                name="theory_file"
                extra=".pdf"
                rules={[{ required: true }]}>
                <Upload
                  accept=".pdf"
                  maxCount={1}
                  extraextra=".pdf"
                  customRequest={handleRequest}
                  onChange={(info) => {
                    if (info.status !== 'uploading') {
                      setTheoryFile(info.file.originFileObj)
                    }
                  }}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
              <Form.Item
                label="File Workshop"
                name="workshop_file"
                rules={[{ required: true }]}
                extra=".pdf">
                <Upload
                  accept=".pdf"
                  maxCount={1}
                  customRequest={handleRequest}
                  onChange={(info) => {
                    if (info.status !== 'uploading') {
                      setWorkshop(info.file.originFileObj)
                    }
                  }}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item> */}

              {/* <Card className="devInfoCard">
                <div className="devInfo">
                  <InfoCircleOutlined
                    style={{ fontSize: 40, marginRight: 10 }}
                  />
                  <p>
                    Pastikan harga yang dimasukkan BENAR, karena fitur edit
                    harga masih dalam proses pengembangan
                  </p>
                </div>
              </Card> */}

              {/* //! START TODO CreateComponent */}
              <Form.List
                name="price"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <Space
                        key={key}
                        style={{
                          display: 'flex',
                          marginBottom: 8,
                        }}
                        align="baseline">
                        <Form.Item
                          label="To"
                          name={[name, 'to']}
                          initialValue={
                            name === 0
                              ? 'umum'
                              : name === 1
                              ? 'mahasiswa'
                              : name === 2
                              ? 'bekerja'
                              : 'freshgraduate'
                          }>
                          <Input size="large" readOnly />
                        </Form.Item>
                        <Form.Item
                          label="Harga"
                          name={[name, 'price']}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                          initialValue={10000}>
                          <InputNumber
                            min={10000}
                            formatter={(value) =>
                              value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            prefix="Rp "
                            style={{ width: '100%' }}
                            size="large"
                            step={10000}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Discount"
                          rules={[
                            {
                              required: true,
                              message: 'Discount tidak boleh kosong, minimal 0',
                            },
                          ]}
                          name={[name, 'discount']}
                          initialValue={0}>
                          <InputNumber
                            min={0}
                            max={100}
                            prefix="% "
                            style={{ width: '100%' }}
                            size="large"
                            step={10}
                          />
                        </Form.Item>
                        {name === 0 ? null : (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(name)}
                          />
                        )}
                      </Space>
                    ))}
                    {fields.length < 4 && (
                      <>
                        <Form.Item>
                          <Button
                            type="dashed"
                            className="btn-add-price"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            size="large">
                            {fields.length < 1
                              ? 'Tambah Harga'
                              : 'Tambah Harga Lainnya'}
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </>
                )}
              </Form.List>
              {/* //! END TODO CreateComponent */}

              <Form.Item label="Tag" name="tag_name">
                <Input placeholder="tag1,tag2,tag3" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                label="Program Unggulan ?"
                name="featured"
                initialValue={false}>
                <Switch checkedChildren="Ya" unCheckedChildren="Tidak" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <div className={styles.container}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProgramForm
