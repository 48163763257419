import Layout from '../../layout'
import styles from './Home.module.css'
import logo from '../../assets/images/logo/mindoDashboard.svg'

// REDUX
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selectors'

const Home = () => {
  const currentUser = useSelector(selectCurrentUser)

  return (
    <Layout>
      <div className={styles.container}>
        <h1>Welcome, {currentUser.user.name}! Have a great day 😄</h1>
        <img src={logo} alt="mindo logo" />
        <h2 className={styles.name}>{currentUser.user.name}</h2>
        <h2 className={styles.role}>{`Role: ${currentUser.user.role}`}</h2>
      </div>
    </Layout>
  )
}

export default Home
