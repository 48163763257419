import Layout from "../../../layout"
import { KuponForm } from "../components"

const KuponEdit = () => {
  return (
    <Layout id="kupon-edit">
      <KuponForm title="Edit Kupon" />
    </Layout>
  )
}

export default KuponEdit
