import Layout from "../../../layout"
import { KuponForm } from "../components"

const KuponTambah = () => {
  return (
    <Layout id="kupon-tambah">
      <KuponForm title="Tambah Kupon" />
    </Layout>
  )
}

export default KuponTambah
