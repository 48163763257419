import { Button, Form, Input, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styles from './KategoriForm.module.css'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import { FetchLoading } from '../../../../components'
import { removeCurrentUser } from '../../../../redux/user/user.action'

message.config({
  maxCount: 1,
})

const KategoriForm = ({ title = '' }) => {
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [kategoriData, setKategoriData] = useState(null)
  const { slug } = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)
  const [form] = Form.useForm()

  useEffect(() => {
    if (slug) {
      axios({
        method: 'get',
        url: `/category/${slug}`,
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
        .then(({ data }) => {
          setKategoriData(data)
          setLoading(false)
        })
        .catch(function (error) {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
        })
    } else {
      setLoading(false)
      setKategoriData({ name: '', code_category: '' })
    }
  }, [currentUser.token, dispatch, navigate, slug])

  const onFinish = (values) => {
    setSubmitLoading(true)
    const { name, code_category } = values
    const data = JSON.stringify({
      name,
      code_category,
    })
    if (slug) {
      axios({
        method: 'patch',
        url: `/category/${slug}`,
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then(() => {
          form.resetFields()
          message.success('Kategori Berhasil DiUpdate')
          navigate('/program/kategori')
        })
        .catch((error) => {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
        })
    } else {
      axios({
        method: 'post',
        url: '/category',
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then(() => {
          form.resetFields()
          message.success('Kategori Berhasil Ditambah')
          navigate('/program/kategori')
        })
        .catch((error) => {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
        })
    }
  }

  return (
    <FetchLoading data={kategoriData}>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1 className={styles.title}>{title}</h1>
          {!loading && (
            <Form
              form={form}
              layout={'vertical'}
              onFinish={onFinish}
              initialValues={{
                name: kategoriData.name,
                code_category: kategoriData.code_category,
              }}>
              <Form.Item
                label="Nama"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Nama belum di isi!',
                  },
                ]}>
                <Input placeholder="Nama Kategori" size="large" />
              </Form.Item>
              <Form.Item
                label="Kode"
                name="code_category"
                rules={[
                  {
                    required: true,
                    message: 'Kode belum di isi!',
                  },
                ]}>
                <Input placeholder="Kode Kategori" size="large" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </FetchLoading>
  )
}

export default KategoriForm
