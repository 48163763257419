import axios from 'axios'
import React, { useEffect, useState } from 'react'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import { removeCurrentUser } from '../../redux/user/user.action'

import ButtonLink from '../../components/ButtonLink'
import FetchLoading from '../../components/FetchLoading'
import Layout from '../../layout'
import { TabelTrainer } from './components'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ROLE_PRODUCT_READ_ONLY } from '../../utils'

const PogramTrainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)
  const [trainers, setTrainers] = useState(null)

  const user_read_only = ROLE_PRODUCT_READ_ONLY.find(
    (role) => role === currentUser.user.role
  )

  useEffect(() => {
    axios({
      method: 'get',
      url: '/trainer',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setTrainers(data)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
  }, [currentUser.token, dispatch, navigate])

  return (
    <Layout id="trainer" title="Trainer">
      {user_read_only ? null : (
        <ButtonLink
          urlRoute="/program/trainer/trainer-tambah"
          title="Tambah Trainer"
        />
      )}
      <FetchLoading data={trainers}>
        <TabelTrainer
          trainers={trainers}
          user_read_only={user_read_only}></TabelTrainer>
      </FetchLoading>
    </Layout>
  )
}

export default PogramTrainer
