import { Button, Form, Input, message, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styles from './PenggunaForm.module.css'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import { removeCurrentUser } from '../../../../redux/user/user.action'

import { useNavigate } from 'react-router-dom'
import { FetchLoading } from '../../../../components'
import { Option } from 'antd/lib/mentions'

message.config({
  maxCount: 1,
})

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} harus diisi!',
}
/* eslint-enable no-template-curly-in-string */

const PenggunaForm = ({ title = '' }) => {
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [dataRole, setDataRole] = useState(null)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)
  const [form] = Form.useForm()

  useEffect(() => {
    axios({
      method: 'get',
      url: '/user/create',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then((response) => {
        setDataRole(response.data)
        setLoading(false)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
  }, [currentUser.token, dispatch, navigate])

  const onFinish = (values) => {
    setSubmitLoading(true)
    const {
      name,
      username,
      email,
      city,
      address,
      phone,
      role,
      password,
      password_confirmation,
    } = values
    const data = JSON.stringify({
      name,
      username,
      email,
      city,
      address,
      phone,
      role,
      password,
      password_confirmation,
    })

    axios({
      method: 'post',
      url: '/user',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(({ data }) => {
        setSubmitLoading(false)
        if (data.status === false) {
          message.error(`${JSON.stringify(data.message)}`)
        } else {
          form.resetFields()
          message.success('Pengguna Berhasil Ditambah')
          navigate('/pengguna')
        }
      })
      .catch((error) => {
        setSubmitLoading(false)
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
  }

  return (
    <FetchLoading data={dataRole}>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1 className={styles.title}>{title}</h1>
          {!loading && (
            <Form
              form={form}
              layout={'vertical'}
              onFinish={onFinish}
              validateMessages={validateMessages}>
              <Form.Item
                label="Nama"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input placeholder="Nama" size="large" />
              </Form.Item>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input placeholder="Username" size="large" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Email Tidak Valid',
                  },
                  {
                    required: true,
                  },
                ]}>
                <Input placeholder="Email" size="large" />
              </Form.Item>
              <Form.Item
                label="Kota"
                name="city"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input placeholder="Kota" size="large" />
              </Form.Item>
              <Form.Item
                label="Alamat"
                name="address"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input placeholder="Alamat" size="large" />
              </Form.Item>
              <Form.Item
                label="Nomor Telepon"
                name="phone"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input placeholder="Nomor Telepon" size="large" />
              </Form.Item>
              <Form.Item label="Role" name="role" rules={[{ required: true }]}>
                <Select placeholder="Pilih Role Pengguna" size="large">
                  {dataRole.role.slice(1).map((item) => (
                    <Option value={item.id_role} key={item.id_role}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                hasFeedback
                rules={[
                  {
                    required: true,
                    min: 8,
                    message: 'Minimal 8 Karakter',
                  },
                ]}>
                <Input.Password placeholder="Password" size="large" />
              </Form.Item>
              <Form.Item
                label="Konfirmasi Password"
                name="password_confirmation"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    min: 8,
                    message: 'Minimal 8 Karakter',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }

                      return Promise.reject(
                        new Error('Password yang anda masukkan tidak sesuai')
                      )
                    },
                  }),
                ]}>
                <Input.Password
                  placeholder="Konfirmasi Password"
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </FetchLoading>
  )
}

export default PenggunaForm
