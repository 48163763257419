import Layout from '../../../layout'
import { TrainerForm } from '../components'

const TrainerTambah = () => {
  return (
    <Layout id="trainer-tambah">
      <TrainerForm title="Tambah Trainer" />
    </Layout>
  )
}

export default TrainerTambah
