import Layout from '../../../layout'
import { PenggunaFormEdit } from '../components'

const PenggunaEdit = () => {
  return (
    <Layout id="pengguna-edit">
      <PenggunaFormEdit title="Edit Pengguna" />
    </Layout>
  )
}

export default PenggunaEdit
