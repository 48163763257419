import Layout from '../../../layout'
import { PesertaFormEdit } from '../components'

const PesertaEdit = () => {
  return (
    <Layout id="peserta-edit">
      <PesertaFormEdit title="Edit Peserta" />
    </Layout>
  )
}

export default PesertaEdit
