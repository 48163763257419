import Layout from '../../layout'
import ProfileForm from './components'

const Profile = () => {
  return (
    <Layout id="profile">
      <ProfileForm title="Profile" />
    </Layout>
  )
}

export default Profile
