import Layout from '../../../layout'
import { FaqForm } from '../components'

const FaqEdit = () => {
  return (
    <Layout id="faq-edit">
      <FaqForm title="Edit FAQ" />
    </Layout>
  )
}

export default FaqEdit
