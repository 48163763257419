import Layout from '../../../layout'
import { PesertaForm } from '../components'

const PesertaTambah = () => {
  return (
    <Layout id="peserta-tambah">
      <PesertaForm title="Tambah Peserta" />
    </Layout>
  )
}

export default PesertaTambah
