const BASE_URL = 'https://api.mindoeducation.co.id'
export const BASE_URL_IMAGE_PROGRAM = `${BASE_URL}/storage/covers`
export const BASE_URL_IMAGE_TESTIMONI = `${BASE_URL}/storage/testimonials`
export const BASE_URL_API = `${BASE_URL}/api`

export const ROLE_USER = [
  'super_admin',
  'lead_product',
  'lead_marketing',
  'lead_finance',
  'finance',
  'product',
  'patnership',
  'social_media',
]
export const ROLE_PRODUCT_READ_ONLY = [
  'lead_finance',
  'finance',
  'patnership',
  'social_media',
]
export const ROLE_PESERTA_READ_ONLY = ['finance', 'patnership', 'social_media']

export const ROLE_PENGGUNA_FALSE = [
  'lead_product',
  'lead_marketing',
  'lead_finance',
  'finance',
  'product',
  'patnership',
  'social_media',
]
export const ROLE_FAQ_FALSE = ['lead_finance', 'finance', 'patnership']
export const ROLE_TESTIMONI_FALSE = ['lead_finance', 'finance', 'patnership']
export const ROLE_TRANSAKSI_FALSE = [
  // 'lead_product',
  // 'product',
  // 'lead_marketing',
  // 'patnership',
  // 'social_media',
]
