import { Table, Space, Rate } from 'antd'
import { Link } from 'react-router-dom'
import ButtonDelete from '../../../../components/ButtonDelete'
const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
  },
  {
    title: 'Pesan',
    dataIndex: 'message',
    key: 'message',
    width: '40%',
  },
  {
    title: 'Rating',
    dataIndex: 'rating',
    key: 'rating',
    render: (record) => <Rate allowHalf disabled defaultValue={record} />,
  },
  {
    title: 'Action',
    key: 'action',
    render: (record) => (
      <Space size="middle" key={record.id}>
        <Link
          className="btn btn-tertiary"
          to={`/testimoni/testimoni-edit/${record.id}`}>
          Edit
        </Link>
        <ButtonDelete
          endPoints="/testimonial"
          id={record.id}
          msgSuccess="Testimoni Berhasil di Delete"
        />
      </Space>
    ),
  },
]

const TabelTestimonial = ({ testimonials }) => {
  return (
    <div>
      <Table
        bordered
        rowKey={(obj) => obj.id}
        columns={columns}
        dataSource={testimonials}
        pagination={{ pageSize: 5, position: ['', 'bottomCenter'] }}
      />
    </div>
  )
}

export default TabelTestimonial
