import { Table, Space, Tag } from 'antd'
import { Link } from 'react-router-dom'
import ButtonDelete from '../../../../components/ButtonDelete'

const columns = (user_read_only) => {
  const column = [
    {
      title: 'Kode',
      dataIndex: 'code',
      key: 'code',
      render: (record) => (
        <Tag color="#213A5E" key={record}>
          {record}
        </Tag>
      ),
    },
    {
      title: 'value',
      dataIndex: 'value',
      key: 'value',
      render: (record) => `${(record / 100) * 100}%`,
      width: '10%',
    },
    {
      title: 'Start',
      dataIndex: 'start_at',
      key: 'start_at',
    },
    {
      title: 'Expire',
      dataIndex: 'expire_at',
      key: 'expire_at',
    },
    {
      title: 'Produk',
      dataIndex: 'product',
      key: 'product',
      render: (record) => record.name,
    },
  ]

  // eslint-disable-next-line no-unused-expressions
  user_read_only
    ? null
    : column.push({
        title: 'Action',
        key: 'action',
        render: (record) => (
          <Space size="middle" key={record.id}>
            <Link
              className="btn btn-tertiary"
              to={`/program/kupon/kupon-edit/${record.id}`}>
              Edit
            </Link>
            <ButtonDelete
              endPoints="/coupon"
              id={record.id}
              msgSuccess="Kategori Berhasil di Delete"
            />
          </Space>
        ),
      })

  return column
}

const TabelKupon = ({ coupons, user_read_only }) => {
  return (
    <div>
      <Table
        bordered
        rowKey={(obj) => obj.id}
        columns={columns(user_read_only)}
        dataSource={coupons}
        pagination={{ pageSize: 5, position: ['', 'bottomCenter'] }}
      />
    </div>
  )
}

export default TabelKupon
