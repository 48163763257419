import Layout from '../../layout'
import TabelPengguna from './components/Table'
import { useEffect, useState } from 'react'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import { removeCurrentUser } from '../../redux/user/user.action'

import FetchLoading from '../../components/FetchLoading'
import ButtonLink from '../../components/ButtonLink'
import axios from 'axios'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'

const Pengguna = () => {
  const currentUser = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [users, setUsers] = useState(null)

  useEffect(() => {
    axios({
      method: 'get',
      url: '/user',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setUsers(data)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error ')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
  }, [currentUser.token, dispatch, navigate])

  return (
    <Layout id="pengguna" title="Pengguna">
      <ButtonLink
        urlRoute="/pengguna/pengguna-tambah"
        title="Tambah Pengguna"
      />
      <FetchLoading data={users}>
        <TabelPengguna users={users}></TabelPengguna>
      </FetchLoading>
    </Layout>
  )
}

export default Pengguna
