import Layout from '../../../layout'
import { FasilitasForm } from '../components'

const FasilitasTambah = () => {
  return (
    <Layout id="fasilitas-tambah">
      <FasilitasForm title="Tambah Fasilitas" />
    </Layout>
  )
}

export default FasilitasTambah
