import React from 'react'
import { Table } from 'antd'
import styles from './Table.module.css'
import columns from './Table.utils'

const TableProgram = ({ programs, user_read_only }) => {
  return (
    <div className={styles.container}>
      <Table
        bordered
        rowKey={(obj) => obj.id}
        columns={columns(user_read_only)}
        dataSource={programs}
        pagination={{ pageSize: 5, position: ['', 'bottomCenter'] }}
      />
    </div>
  )
}

export default TableProgram
