import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  DatePicker,
  Select,
} from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styles from './KuponForm.module.css'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import { removeCurrentUser } from '../../../../redux/user/user.action'

import { useNavigate, useParams } from 'react-router-dom'
import { FetchLoading } from '../../../../components'
import moment from 'moment'
import { Option } from 'antd/lib/mentions'

const KuponForm = ({ title = '' }) => {
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [KuponData, setKuponData] = useState(null)
  const [ProdukData, setProdukData] = useState(null)
  const dispatch = useDispatch()
  const { slug } = useParams()

  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)
  const [form] = Form.useForm()

  useEffect(() => {
    if (slug) {
      axios({
        method: 'get',
        url: `/coupon/${slug}`,
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
        .then(({ data }) => {
          setProdukData(data.product)
          setKuponData(data.coupon)
          setLoading(false)
        })
        .catch((error) => {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
        })
    } else {
      setKuponData({
        code: '',
        value: '',
        product_id: '',
        start_at: moment().format(),
        expire_at: moment().add(10, 'days').format(),
      })
      axios({
        method: 'get',
        url: '/coupon/create',
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
        .then(({ data }) => {
          setProdukData(data.product)
          setLoading(false)
        })
        .catch((error) => {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
        })
    }
  }, [currentUser.token, dispatch, navigate, slug])

  const onFinish = (values) => {
    setSubmitLoading(true)
    const { code, value, product_id, start_at, expire_at } = values
    const data = JSON.stringify({
      code,
      value,
      product_id,
      start_at: start_at.toISOString().replace('T', ' ').slice(0, 16),
      expire_at: expire_at.toISOString().replace('T', ' ').slice(0, 16),
    })

    console.log(value)

    if (slug) {
      axios({
        method: 'patch',
        url: `/coupon/${slug}`,
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then(() => {
          form.resetFields()
          message.success('Kupon Berhasil DiUpdate')
          navigate('/program/kupon')
        })
        .catch((error) => {
          setSubmitLoading(false)
          if (error.response.data.message.product_id) {
            message.error(`Produk sudah memiliki kupon`)
          }
          if (error.response.data.message.code[0]) {
            message.error(`Kode sudah digunakan`)
          }
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
        })
    } else {
      axios({
        method: 'post',
        url: '/coupon',
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then(() => {
          form.resetFields()
          message.success('Kupon Berhasil Ditambah')
          navigate('/program/kupon')
        })
        .catch((error) => {
          setSubmitLoading(false)
          if (error.response.data.message.product_id) {
            message.error(`Produk sudah memiliki kupon`)
          }
          if (error.response.data.message.code[0]) {
            message.error(`Kode sudah digunakan`)
          }
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
        })
    }
  }

  return (
    <FetchLoading data={ProdukData}>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1 className={styles.title}>{title}</h1>
          {!loading && (
            <Form
              form={form}
              layout={'vertical'}
              onFinish={onFinish}
              initialValues={{
                code: KuponData.code,
                value: KuponData.value,
                product_id: parseInt(KuponData.product_id)
                  ? parseInt(KuponData.product_id)
                  : 'Pilih Produk',
                start_at: moment(KuponData.start_at, 'YYYY-MM-DD hh:mm:ss'),
                expire_at: moment(KuponData.expire_at, 'YYYY-MM-DD hh:mm:ss'),
              }}>
              <Form.Item
                label="Kode"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Kode belum di isi!',
                  },
                ]}>
                <Input placeholder="Kode Kupon" size="large" />
              </Form.Item>
              <Form.Item
                label="Value"
                name="value"
                rules={[
                  {
                    required: true,
                    message: 'Value belum di isi!',
                  },
                ]}>
                <InputNumber
                  placeholder="%"
                  min={10}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                  size="large"
                  step={10}
                />
              </Form.Item>
              <Form.Item
                label="Produk"
                name="product_id"
                rules={[
                  {
                    required: true,
                    message: 'Produk belum di isi!',
                  },
                ]}>
                <Select placeholder="Pilih Produk">
                  {ProdukData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Start"
                name="start_at"
                rules={[
                  {
                    required: true,
                    message: 'Start belum di isi!',
                  },
                ]}>
                <DatePicker showTime size="large" />
              </Form.Item>
              <Form.Item
                label="Expire"
                name="expire_at"
                rules={[
                  {
                    required: true,
                    message: 'Expire belum di isi!',
                  },
                ]}>
                <DatePicker showTime size="large" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </FetchLoading>
  )
}

export default KuponForm
