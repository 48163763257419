import React from 'react'
import 'moment-timezone'

// ROUTER
import { Routes, Route, Navigate } from 'react-router-dom'

// REDUX
import { useSelector } from 'react-redux'
import { selectCurrentUser } from './redux/user/user.selectors'

// GLOBAL STYLING
import 'antd/dist/antd.min.css'
import './App.scss'

// IMPORT COMPONENTS PAGE
import NoMatch from './pages/NoMatch/'
import Login from './pages/Login'
import Home from './pages/Home'
import Program from './pages/Program'
import ProgramTambah from './pages/Program/ProgramTambah'
import ProgramEdit from './pages/Program/ProgramEdit'
import ProgramKategori from './pages/ProgramKategori'
import KategoriTambah from './pages/ProgramKategori/KategoriTambah'
import KategoriEdit from './pages/ProgramKategori/KategoriEdit'
import ProgramFasilitas from './pages/ProgramFasilitas'
import FasilitasTambah from './pages/ProgramFasilitas/FasilitasTambah'
import FasilitasEdit from './pages/ProgramFasilitas/FasiltiasEdit'
import ProgramKupon from './pages/ProgramKupon'
import KuponTambah from './pages/ProgramKupon/KuponTambah'
import KuponEdit from './pages/ProgramKupon/KuponEdit'
import ProgramTrainer from './pages/ProgramTrainer'
import ProgramType from './pages/ProgramType'
import Faq from './pages/Faq'
import FaqTambah from './pages/Faq/FaqTambah'
import FaqEdit from './pages/Faq/FaqEdit'
import Testimonial from './pages/Testimonial'
import TestimonialTambah from './pages/Testimonial/TestimonialTambah'
import TestimoniEdit from './pages/Testimonial/TestimoniEdit'
import Pengguna from './pages/Pengguna'
import PenggunaTambah from './pages/Pengguna/PenggunaTambah'
import PenggunaEdit from './pages/Pengguna/PenggunaEdit'
import TrainerTambah from './pages/ProgramTrainer/TrainerTambah'
import TrainerEdit from './pages/ProgramTrainer/TrainerEdit'
import TypeEdit from './pages/ProgramType/TypeEdit'
import Peserta from './pages/Peserta'
import PesertaTambah from './pages/Peserta/PesertaTambah'
import PesertaEdit from './pages/Peserta/PesertaEdit'
import Profile from './pages/Profile'

import { message } from 'antd'
import Transaksi from './pages/Transaksi'
import DetailTransaksi from './pages/Transaksi/DetailTransaksi'
import ProgramDuplicate from './pages/Program/ProgramDuplicate'

message.config({
  maxCount: 1,
})

const App = () => {
  const currentUser = useSelector(selectCurrentUser)

  return (
    <div>
      <Routes>
        {!currentUser && <Route path="/login" element={<Login />} />}

        {currentUser && (
          <>
            <Route path="/" element={<Home />} />
            <Route exact path="/program" element={<Program />} />
            <Route
              exact
              path="/program/program-tambah"
              element={<ProgramTambah />}
            />
            <Route
              path="/program/program-edit/:slug"
              element={<ProgramEdit />}
            />
            <Route
              path="/program/program-duplikat/:slug"
              element={<ProgramDuplicate />}
            />
            <Route
              exact
              path="/program/kategori"
              element={<ProgramKategori />}
            />
            <Route
              exact
              path="/program/kategori/kategori-tambah"
              element={<KategoriTambah />}
            />
            <Route
              exact
              path="/program/kategori/kategori-edit/:slug"
              element={<KategoriEdit />}
            />
            <Route
              exact
              path="/program/fasilitas"
              element={<ProgramFasilitas />}
            />
            <Route
              exact
              path="/program/fasilitas/fasilitas-tambah"
              element={<FasilitasTambah />}
            />
            <Route
              exact
              path="/program/fasilitas/fasilitas-edit/:slug"
              element={<FasilitasEdit />}
            />
            <Route exact path="/program/type" element={<ProgramType />} />
            <Route
              exact
              path="/program/type/type-edit/:slug"
              element={<TypeEdit />}
            />
            <Route exact path="/program/kupon" element={<ProgramKupon />} />
            <Route
              exact
              path="/program/kupon/kupon-tambah"
              element={<KuponTambah />}
            />
            <Route
              exact
              path="/program/kupon/kupon-edit/:slug"
              element={<KuponEdit />}
            />
            <Route exact path="/program/trainer" element={<ProgramTrainer />} />
            <Route
              exact
              path="/program/trainer/trainer-tambah"
              element={<TrainerTambah />}
            />
            <Route
              exact
              path="/program/trainer/trainer-edit/:slug"
              element={<TrainerEdit />}
            />
            <Route exact path="/faq" element={<Faq />} />
            <Route exact path="/faq/faq-tambah" element={<FaqTambah />} />
            <Route exact path="/faq/faq-edit/:slug" element={<FaqEdit />} />
            <Route exact path="/testimoni" element={<Testimonial />} />
            <Route
              exact
              path="/testimoni/testimoni-tambah"
              element={<TestimonialTambah />}
            />
            <Route
              exact
              path="/testimoni/testimoni-edit/:slug"
              element={<TestimoniEdit />}
            />
            <Route exact path="/transaksi" element={<Transaksi />} />
            <Route
              exact
              path="/transaksi/:slug"
              element={<DetailTransaksi />}
            />
            <Route exact path="/pengguna" element={<Pengguna />} />
            <Route
              exact
              path="/pengguna/pengguna-tambah"
              element={<PenggunaTambah />}
            />
            <Route
              exact
              path="/pengguna/pengguna-edit/:slug"
              element={<PenggunaEdit />}
            />
            <Route exact path="/peserta" element={<Peserta />} />
            <Route
              exact
              path="/peserta/peserta-tambah"
              element={<PesertaTambah />}
            />
            <Route
              exact
              path="/peserta/peserta-edit/:slug"
              element={<PesertaEdit />}
            />
            <Route exact path="/profile" element={<Profile />} />
          </>
        )}

        <Route
          path="*"
          element={currentUser ? <NoMatch /> : <Navigate to={'/login'} />}
        />
      </Routes>
    </div>
  )
}

export default App
