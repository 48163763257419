import { Table, Space, Tag } from 'antd'
import { Link } from 'react-router-dom'
import ButtonBlock from '../../../../components/ButtonBlock'
import ButtonDelete from '../../../../components/ButtonDelete'
import ButtonUnblock from '../../../../components/ButtonUnblock'

const columns = (user_read_only) => {
  const column = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Tanggal Verifikasi',
      dataIndex: 'email_verified_at',
      key: 'email_verified_at',
      render: (email_verified_at) =>
        email_verified_at
          ? email_verified_at.slice(0, 10).split('-').reverse().join('-')
          : null,
      width: '10%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (record) =>
        record ? (
          <Tag color="#213A5E" key={record}>
            {record}
          </Tag>
        ) : null,
    },
    {
      title: 'Kota',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Alamat',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Kode Pos',
      dataIndex: 'zip_code',
      key: 'zip_code',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
  ]

  // eslint-disable-next-line no-unused-expressions
  user_read_only
    ? null
    : column.push({
        title: 'Action',
        key: 'action',
        render: (record) => (
          <Space size="middle" key={record.id}>
            <Link
              className="btn btn-tertiary"
              to={`/peserta/peserta-edit/${record.id}`}>
              Edit
            </Link>
            <ButtonDelete
              endPoints="/peserta"
              id={record.id}
              msgSuccess="Peserta Berhasil di Delete"
            />
            {record.blocked === '0' ? (
              <ButtonBlock
                endPoints="/peserta/block"
                id={record.id}
                msgSuccess="Peserta Berhasil di Blokir"
              />
            ) : (
              <ButtonUnblock
                endPoints="/peserta/unblock"
                id={record.id}
                msgSuccess="Peserta Berhasil Membuka Blokir"
              />
            )}
          </Space>
        ),
      })

  return column
}

const TabelPeserta = ({ peserta, user_read_only }) => {
  console.log(peserta)
  return (
    <div>
      <Table
        bordered
        rowKey={(obj) => obj.id}
        columns={columns(user_read_only)}
        dataSource={peserta}
        pagination={{ pageSize: 5, position: ['', 'bottomCenter'] }}
      />
    </div>
  )
}

export default TabelPeserta
