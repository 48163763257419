import { message } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ButtonLink, FetchLoading, SearchData } from '../../components'

// COMPONENTS
import Layout from '../../layout'
import { removeCurrentUser } from '../../redux/user/user.action'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import { FilterCategory, TableProgram } from './components'
import styles from './Program.module.css'

import { ROLE_PRODUCT_READ_ONLY } from '../../utils'

const Program = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)
  const user_read_only = ROLE_PRODUCT_READ_ONLY.find(
    (role) => role === currentUser.user.role
  )
  const [programs, setPrograms] = useState(null)
  const [categoryData, setCategoryData] = useState(null)

  const [filterCategoryField, setFilterCategoryField] = useState('')
  const [searchField, setSearchField] = useState('')

  useEffect(() => {
    axios({
      method: 'get',
      url: '/product',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        console.log(data)
        setCategoryData(data.category)
        setPrograms(data.product)
        setCategoryData(data.category)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
    return () => {}
  }, [currentUser.token, dispatch, navigate])

  // FILLTERING PROGRAM BY SEARCH PROGRAM OR CATEGORY
  const handleFilterCategory = (datas) => {
    return datas.filter(
      (data) => data.category.code_category === filterCategoryField
    )
  }
  const handleFilterSearch = (datas) => {
    return datas.filter((data) =>
      data.name.toLowerCase().includes(searchField.toLowerCase())
    )
  }
  const handleFilteringProgram = () => {
    let programFilter = programs
    if (searchField.length !== 0 && filterCategoryField.length !== 0) {
      programFilter = handleFilterCategory(programFilter)
      programFilter = handleFilterSearch(programFilter)
    } else if (searchField.length) {
      programFilter = handleFilterSearch(programFilter)
    } else if (filterCategoryField.length) {
      programFilter = handleFilterCategory(programFilter)
    }
    return programFilter
  }

  const programFilter = programs !== null ? handleFilteringProgram() : programs

  // FILTERING SEARCH OPTION SUGGESTION BY CATEGORY
  const handleFilterSearchOption = () => {
    let searchOption = programs
    if (filterCategoryField.length !== 0) {
      searchOption = searchOption.filter(
        (program) => program.category.code_category === filterCategoryField
      )
    }
    searchOption = searchOption.map((program) => {
      return { value: program.name, label: program.name }
    })
    return searchOption
  }

  // console.log(programs)

  const searchOptionFilter = programs ? handleFilterSearchOption() : null

  programFilter?.sort(
    (a, b) =>
      Number(new Date(b.time_start.slice(0, 10))) -
      Number(new Date(a.time_start.slice(0, 10)))
  )

  return (
    <Layout id="program" title="Program">
      {user_read_only ? null : (
        <ButtonLink urlRoute="/program/program-tambah" title="Tambah Program" />
      )}
      <FetchLoading data={programs}>
        <div className={styles.filterGroup}>
          <SearchData
            setSearchField={setSearchField}
            optionSearchField={searchOptionFilter}
          />
          <FilterCategory
            setFilterCategoryField={setFilterCategoryField}
            categoryData={categoryData}
          />
        </div>
        <TableProgram
          programs={programFilter}
          user_read_only={user_read_only}
        />
        <p>
          *Data tabel diatur secara <b>Descending</b> berdasarkan tanggal
          pelatihan
        </p>
      </FetchLoading>
    </Layout>
  )
}

export default Program
