/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import styles from './ProgramFormEdit.module.css'
import './ProgramFormEdit.scss'
import axios from 'axios'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import { removeCurrentUser } from '../../../../redux/user/user.action'

// COMPONENTS
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Rate,
  Row,
  Select,
  Spin,
  Switch,
  Upload,
} from 'antd'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
const { Option } = Select

const { RangePicker } = DatePicker

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} harus diisi!',
}
/* eslint-enable no-template-curly-in-string */

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

const ProgramFormEdit = ({ initData = null, title = '' }) => {
  const currentUser = useSelector(selectCurrentUser)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [description, setDescription] = useState('')
  const [syllabus, setSyllabus] = useState('')
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { slug } = useParams()
  const navigate = useNavigate()

  // Upload Form
  const [loading, setLoading] = useState(true)
  const [imageUrl, setImageUrl] = useState(null)
  const [cover, setCover] = useState(null)
  // const [theoryFile, setTheoryFile] = useState(null)
  // const [workshop, setWorkshop] = useState(null)

  const facilityName = []
  const tagName = []

  const uploadButton = (
    <div style={{ margin: 50 }}>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const handleRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      setCover(info.file.originFileObj)
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImageUrl(imageUrl),
        setLoading(false)
      )
    }
  }

  const onFinish = (values) => {
    setSubmitLoading(true)
    const {
      name,
      name_pic,
      category_id,
      type,
      type_product,
      trainer_id,
      facility_name,
      duration,
      rating,
      status,
      kuota,
      link_group,
      link_zoom,
      link_campsite,
      link_theory,
      link_workshop,
      price_umum,
      discount_umum,
      price_mahasiswa,
      discount_mahasiswa,
      price_bekerja,
      discount_bekerja,
      price_freshgraduate,
      discount_freshgraduate,
      tag_name,
      featured,
    } = values

    const time = {
      time_start: duration[0].format('YYYY-MM-DD HH:mm'),
      time_end: duration[1].format('YYYY-MM-DD HH:mm'),
    }

    const price = [
      {
        price: price_umum,
        discount: discount_umum,
        to: 'umum',
      },
      {
        price: price_mahasiswa,
        discount: discount_mahasiswa,
        to: 'mahasiswa',
      },
      {
        price: price_bekerja,
        discount: discount_bekerja,
        to: 'bekerja',
      },
      {
        price: price_freshgraduate,
        discount: discount_freshgraduate,
        to: 'freshgraduate',
      },
    ]

    console.log(price)

    const FormData = require('form-data')
    const data = new FormData()
    data.append('name', name)
    if (description) {
      data.append('description', description)
    } else {
      data.append('description', initData.product[0].description)
    }
    if (cover != null) {
      data.append('cover', cover)
    }
    data.append('category_id', category_id)
    data.append('trainer_id', trainer_id)
    data.append('type', type)
    data.append('type_product', type_product)
    data.append('time_start', time.time_start)
    data.append('time_end', time.time_end)
    data.append('name_pic', name_pic === '' ? null : name_pic)
    data.append('kuota', kuota)
    // if (theoryFile != null) {
    //   data.append('theory_file', theoryFile)
    // }
    // if (workshop != null) {
    //   data.append('workshop_file', workshop)
    // }
    data.append('link_theory', link_theory === '' ? null : link_theory)
    data.append('link_workshop', link_workshop === '' ? null : link_workshop)
    data.append('link_zoom', link_zoom === '' ? null : link_zoom)
    data.append('link_group', link_group === '' ? null : link_group)
    data.append('link_campsite', link_campsite === '' ? null : link_campsite)
    data.append('rating', rating)
    data.append('status', status)
    if (syllabus) {
      data.append('syllabus', syllabus)
    } else {
      data.append('syllabus', initData.product[0].syllabus)
    }
    data.append('price', JSON.stringify(price))
    data.append('facility_name', facility_name)
    data.append('tag_name', tag_name)
    data.append('featured', featured === true ? '1' : '0')

    axios({
      method: 'post',
      url: `/product/update/${slug}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
      data: data,
    })
      .then((response) => {
        if (response.data.status === false) {
          message.error('Gagal Update Program, Periksa Kembali')
          setSubmitLoading(false)
        } else {
          form.resetFields()
          message.success('Program Berhasil DiUpdate')
          navigate('/program')
        }
      })
      .catch((error) => {
        setSubmitLoading(false)
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
  }

  // init price umum
  const priceUmum = initData?.product[0].product_price[0]?.price
  const discounUmum = initData?.product[0].product_price[0]?.discount

  // init price mahasiswa
  const priceMahasiswa = initData?.product[0].product_price[1]?.price
  const discounMahasiswa = initData?.product[0].product_price[1]?.discount

  // init price bekerja
  const priceBekerja = initData?.product[0].product_price[2]?.price
  const discounBekerja = initData?.product[0].product_price[2]?.discount

  // init price freshgraduate
  const priceFreshgraduate = initData?.product[0].product_price[3]?.price
  const discounFreshgraduate = initData?.product[0].product_price[3]?.discount

  console.log(initData)

  return (
    <div id="programEditPage" className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>{title}</h1>
        {initData ? (
          <>
            {initData.product[0].tag_product.map((data) => {
              tagName.push(data.tag.tag)
            })}
            {initData.product[0].facility_product.map((data) => {
              facilityName.push(data.facility.name)
            })}
            <Form
              form={form}
              layout={'vertical'}
              onFinish={onFinish}
              initialValues={{
                name: initData.product[0].name,
                name_pic:
                  initData.product[0].name_pic === 'null' || null
                    ? '-'
                    : initData.product[0].name_pic,
                category_id: initData.product[0].category.id,
                type: initData.type_product[0].id,
                type_product: initData.product[0].online_product[0]?.type,
                trainer_id: initData.product[0].trainer.id,
                facility_name: facilityName,
                duration: [
                  moment(initData.product[0].time_start, 'YYYY-MM-DD HH:mm'),
                  moment(initData.product[0].time_end, 'YYYY-MM-DD HH:mm'),
                ],
                rating: initData.product[0].rating,
                status: initData.product[0].status,
                kuota: initData.product[0].kuota,
                link_group:
                  initData.product[0].link_group === 'null' || null
                    ? '-'
                    : initData.product[0].link_group,
                link_zoom:
                  initData.product[0].link_zoom === 'null' || null
                    ? '-'
                    : initData.product[0].link_zoom,
                link_campsite:
                  initData.product[0].link_campsite === 'null' || null
                    ? '-'
                    : initData.product[0].link_campsite,
                link_theory:
                  initData.product[0].link_theory === 'null' || null
                    ? '-'
                    : initData.product[0].link_theory,
                link_workshop:
                  initData.product[0].link_workshop === 'null' || null
                    ? '-'
                    : initData.product[0].link_workshop,

                price_umum: priceUmum,
                discount_umum: discounUmum,

                price_mahasiswa: priceMahasiswa ? priceMahasiswa : priceUmum,
                discount_mahasiswa: discounMahasiswa
                  ? discounMahasiswa
                  : discounUmum,

                price_bekerja: priceBekerja ? priceBekerja : priceUmum,
                discount_bekerja: discounBekerja ? discounBekerja : discounUmum,

                price_freshgraduate: priceFreshgraduate
                  ? priceFreshgraduate
                  : priceUmum,
                discount_freshgraduate: discounFreshgraduate
                  ? discounFreshgraduate
                  : discounUmum,

                tag_name: tagName.join() === 'null' ? '-' : tagName.join(),

                featured: initData.product[0].featured === '1' ? true : false,
              }}
              autoComplete="off"
              validateMessages={validateMessages}>
              <Form.Item
                label="Nama Program"
                name="name"
                rules={[{ required: true }]}>
                <Input placeholder="Nama Program" size="large" />
              </Form.Item>
              <Form.Item label="Nama PIC" name="name_pic">
                <Input placeholder="Nama PIC" size="large" />
              </Form.Item>
              <Form.Item
                name="category_id"
                label="Category Program"
                rules={[{ required: true }]}>
                <Select placeholder="Pilih Category Program">
                  {initData.category.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                <Select placeholder="Pilih Type">
                  {initData.type_product.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="type_product"
                label="Type Program"
                rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value="reguler">Reguler</Radio>
                  <Radio value="integrated">Integrated</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="trainer_id"
                label="Trainer"
                rules={[{ required: true }]}>
                <Select placeholder="Pilih Trainer">
                  {initData.trainer.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="facility_name"
                label="Fasilitas Program"
                rules={[{ required: true }]}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Pilih Fasilitas">
                  {initData.facility.map((item) => (
                    <Option value={item.name} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Durasi"
                name="duration"
                rules={[{ required: true }]}>
                <RangePicker
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                />
              </Form.Item>
              <Form.Item
                label="Rating"
                name="rating"
                rules={[{ required: true }]}>
                <Rate allowHalf />
              </Form.Item>
              <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true }]}>
                <Select placeholder="Pilih Status">
                  <Option value="active">Active</Option>
                  <Option value="recall">Recall</Option>
                  <Option value="draft">Draft</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Kuota"
                name="kuota"
                rules={[{ required: true }]}>
                <InputNumber
                  style={{ width: 150 }}
                  min={1}
                  placeholder="Jumlah Kuota"
                />
              </Form.Item>
              <Form.Item label="Link Group" name="link_group">
                <Input placeholder="Link Group" size="large" />
              </Form.Item>
              <Form.Item label="Link Zoom" name="link_zoom">
                <Input placeholder="Link Zoom" size="large" />
              </Form.Item>
              <Form.Item label="Link Campsite" name="link_campsite">
                <Input placeholder="Link Campsite" size="large" />
              </Form.Item>
              <Form.Item label="Silabus" name="syllabus">
                <CKEditor
                  editor={ClassicEditor}
                  data={initData.product[0].syllabus}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setSyllabus(data)
                  }}
                />
              </Form.Item>
              <Form.Item label="Deskripsi Program" name="description">
                <CKEditor
                  editor={ClassicEditor}
                  data={initData.product[0].description}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setDescription(data)
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Cover"
                name="cover"
                extra="Image (JPG/PNG/JPEG)">
                <Upload
                  accept="image/png, image/jpeg, image/jpg"
                  style={{ width: '100%' }}
                  name="cover"
                  listType="picture-card"
                  className="avatar-uploader"
                  customRequest={handleRequest}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}>
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="cover"
                      style={{ maxHeight: '300px' }}
                    />
                  ) : initData.product[0].cover ? (
                    <img
                      src={`https://api.mindoeducation.co.id/storage/covers/${initData.product[0].cover}`}
                      alt="cover"
                      style={{ maxHeight: '300px' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <Form.Item label="Link Teori" name="link_theory">
                <Input placeholder="Link Teori" size="large" />
              </Form.Item>
              <Form.Item label="Link Workshop" name="link_workshop">
                <Input placeholder="Link Workshop" size="large" />
              </Form.Item>
              {/* <Form.Item label="File Teori" name="theory_file" extra=".pdf">
                <Upload
                  accept=".pdf"
                  customRequest={handleRequest}
                  maxCount={1}
                  onChange={(info) => {
                    if (info.status !== 'uploading') {
                      setTheoryFile(info.file.originFileObj)
                    }
                  }}
                  defaultFileList={[
                    {
                      uid: '1',
                      name: 'File Teori.pdf',
                      status: 'done',
                      url: `https://api.mindoeducation.co.id/storage/theory/${initData.product[0].theory_file}`,
                    },
                  ]}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
              <Form.Item
                label="File Workshop"
                name="workshop_file"
                extra=".pdf">
                <Upload
                  accept=".pdf"
                  customRequest={handleRequest}
                  maxCount={1}
                  onChange={(info) => {
                    if (info.status !== 'uploading') {
                      setWorkshop(info.file.originFileObj)
                    }
                  }}
                  defaultFileList={[
                    {
                      uid: '1',
                      name: 'File Workshop.pdf',
                      status: 'done',
                      url: `https://api.mindoeducation.co.id/storage/workshop/${initData.product[0].workshop_file}`,
                    },
                  ]}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item> */}

              {/* <Card className="devInfoCard">
                <div className="devInfo">
                  <WarningOutlined style={{ fontSize: 40, marginRight: 10 }} />
                  <p>
                    Sementara harga tidak dapat diubah, karena masih dalam
                    proses pengembangan, Jika ingin mengubahnya, maka lakukan
                    pengimputan ulang produk
                  </p>
                </div>
              </Card> */}
              <Card
                title="Harga Umum"
                bordered={false}
                style={{ width: '100%' }}>
                <Row gutter={[48, 16]}>
                  <Col span={12}>
                    <Form.Item label="Harga" name="price_umum">
                      <InputNumber
                        min={10000}
                        formatter={(value) =>
                          value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        prefix="Rp "
                        size="large"
                        style={{ width: '100%' }}
                        step={10000}
                        // readOnly
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Discount" name="discount_umum">
                      <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace('%', '')}
                        size="large"
                        step={10}
                        // readOnly
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card
                title="Harga Mahasiswa"
                bordered={false}
                style={{ width: '100%' }}>
                <Row gutter={[48, 16]}>
                  <Col span={12}>
                    <Form.Item label="Harga" name="price_mahasiswa">
                      <InputNumber
                        min={10000}
                        formatter={(value) =>
                          value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        prefix="Rp "
                        size="large"
                        style={{ width: '100%' }}
                        step={10000}
                        // readOnly
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Discount" name="discount_mahasiswa">
                      <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace('%', '')}
                        size="large"
                        step={10}
                        // readOnly
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card
                title="Harga Bekerja"
                bordered={false}
                style={{ width: '100%' }}>
                <Row gutter={[48, 16]}>
                  <Col span={12}>
                    <Form.Item label="Harga" name="price_bekerja">
                      <InputNumber
                        min={10000}
                        formatter={(value) =>
                          value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        prefix="Rp "
                        size="large"
                        style={{ width: '100%' }}
                        step={10000}
                        // readOnly
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Discount" name="discount_bekerja">
                      <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace('%', '')}
                        size="large"
                        step={10}
                        // readOnly
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card
                title="Harga Freshgraduate"
                bordered={false}
                style={{ width: '100%' }}>
                <Row gutter={[48, 16]}>
                  <Col span={12}>
                    <Form.Item label="Harga" name="price_freshgraduate">
                      <InputNumber
                        min={10000}
                        formatter={(value) =>
                          value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        prefix="Rp "
                        size="large"
                        style={{ width: '100%' }}
                        step={10000}
                        // readOnly
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Discount" name="discount_freshgraduate">
                      <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace('%', '')}
                        size="large"
                        step={10}
                        // readOnly
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              <Form.Item label="Tag" name="tag_name">
                <Input placeholder="tag1,tag2,tag3" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item label="Program Unggulan ?" name="featured">
                <Switch
                  checkedChildren="Ya"
                  unCheckedChildren="Tidak"
                  defaultChecked={
                    initData?.product[0].featured === '1' ? true : false
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <div className={styles.container}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProgramFormEdit
