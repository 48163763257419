import { Button, Form, Input, message, Upload } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styles from './FasilitasForm.module.css'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import { FetchLoading } from '../../../../components'
import { removeCurrentUser } from '../../../../redux/user/user.action'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

message.config({
  maxCount: 1,
})

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/svg+xml' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload SVG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

const FasilitasForm = ({ title = '' }) => {
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const [file, setFile] = useState(null)
  const [fasilitasData, setFasilitasData] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const { slug } = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)
  const [form] = Form.useForm()

  const validateUpload = () => {
    if (slug) {
      return [
        {
          required: false,
        },
      ]
    } else {
      return [
        {
          required: true,
          message: 'Icon belum di isi!',
        },
      ]
    }
  }

  useEffect(() => {
    if (slug) {
      axios({
        method: 'get',
        url: `/facility/${slug}`,
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
        .then(({ data }) => {
          setFasilitasData(data)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
        })
    } else {
      setLoading(false)
      setFasilitasData({
        name: '',
      })
    }
  }, [currentUser.token, dispatch, navigate, slug])

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setStatus(true)
      return
    }
    if (info.file.status === 'done') {
      setFile(info.file.originFileObj)
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImageUrl(imageUrl),
        setStatus(false)
      )
    }
  }

  const uploadButton = (
    <div style={{ margin: 50 }}>
      {status ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const handleRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const onFinish = (values) => {
    setSubmitLoading(true)
    const { name } = values
    var data = new FormData()
    data.append('name', name)
    if (file != null) {
      data.append('icon', file)
    }
    if (slug) {
      axios({
        method: 'post',
        url: `/facility/${slug}`,
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then(() => {
          form.resetFields()
          message.success('Fasilitas Berhasil DiUpdate')
          navigate('/program/fasilitas')
        })
        .catch((error) => {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
          setSubmitLoading(false)
        })
    } else {
      axios({
        method: 'post',
        url: '/facility',
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then(() => {
          form.resetFields()
          message.success('Fasilitas Berhasil Ditambah')
          navigate('/program/fasilitas')
        })
        .catch((error) => {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
          setSubmitLoading(false)
        })
    }
  }

  // console.log(fasilitasData)

  return (
    <FetchLoading data={fasilitasData}>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1 className={styles.title}>{title}</h1>
          {!loading && (
            <Form
              form={form}
              layout={'vertical'}
              onFinish={onFinish}
              initialValues={{ name: fasilitasData.name }}>
              <Form.Item
                label="Nama Fasilitas"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input placeholder="Nama Fasilitas" size="large" />
              </Form.Item>
              <Form.Item
                label="Upload Icon"
                name="icon"
                extra="Icon (SVG/PNG)"
                rules={validateUpload()}>
                <Upload
                  accept="image/png, .svg"
                  name="icon"
                  listType="picture-card"
                  className="avatar-uploader"
                  customRequest={handleRequest}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}>
                  {imageUrl ? (
                    <img src={imageUrl} alt="icon" style={{ width: 100 }} />
                  ) : fasilitasData.icon ? (
                    <img
                      src={`https://api.mindoeducation.co.id/storage/facility/${fasilitasData.icon}`}
                      alt="icon"
                      style={{ width: 30 }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </FetchLoading>
  )
}

export default FasilitasForm
