import { Button, Form, Input, message, Select, Tabs } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styles from './PenggunaFormEdit.module.css'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import { removeCurrentUser } from '../../../../redux/user/user.action'

import { useNavigate, useParams } from 'react-router-dom'
import { FetchLoading } from '../../../../components'
import { Option } from 'antd/lib/mentions'

const { TabPane } = Tabs

message.config({
  maxCount: 1,
})

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} harus diisi!',
}
/* eslint-enable no-template-curly-in-string */

const PenggunaFormEdit = ({ title = '' }) => {
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [penggunaData, setPenggunaData] = useState(null)
  const [dataRole, setDataRole] = useState(null)
  const dispatch = useDispatch()
  const { slug } = useParams()

  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)
  const [form] = Form.useForm()

  useEffect(() => {
    axios({
      method: 'get',
      url: `/user/${slug}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setDataRole(data[0])
        setPenggunaData(data[1])
        setLoading(false)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
  }, [currentUser.token, dispatch, navigate, slug])

  const onFinish = (values) => {
    setSubmitLoading(true)
    const {
      name,
      username,
      email,
      city,
      address,
      phone,
      role,
      old_password,
      password,
      password_confirmation,
    } = values

    const data = JSON.stringify({
      name,
      username,
      email,
      city,
      address,
      phone,
      role,
      old_password,
      password,
      password_confirmation,
    })

    axios({
      method: 'patch',
      url: `/user/${slug}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((response) => {
        if (response.data.status === false) {
          message.error('Gagal Update Pengguna, Periksa Kembali')
          setSubmitLoading(false)
        } else {
          form.resetFields()
          message.success('Pengguna Berhasil DiUpdate')
          navigate('/pengguna')
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          error.response.data.message
            ? message.error(error.response.data.message)
            : message.error('403 Forbidden')
        }
        setSubmitLoading(false)
      })
  }

  return (
    <FetchLoading data={dataRole}>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1 className={styles.title}>{title}</h1>
          {!loading && (
            <Tabs defaultActiveKey="1">
              <TabPane tab="Data Pengguna" key="1">
                <Form
                  form={form}
                  layout={'vertical'}
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                  initialValues={{
                    name: penggunaData.name,
                    username: penggunaData.username,
                    email: penggunaData.email,
                    city: penggunaData.city,
                    address: penggunaData.address,
                    phone: penggunaData.phone,
                    role: penggunaData.role,
                  }}>
                  <Form.Item
                    label="Nama"
                    name="name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Nama" size="large" />
                  </Form.Item>
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Username" size="large" />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'Email Tidak Valid',
                      },
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Email" size="large" />
                  </Form.Item>
                  <Form.Item
                    label="Kota"
                    name="city"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Kota" size="large" />
                  </Form.Item>
                  <Form.Item
                    label="Alamat"
                    name="address"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Alamat" size="large" />
                  </Form.Item>
                  <Form.Item
                    label="Nomor Telepon"
                    name="phone"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Nomor Telepon" size="large" />
                  </Form.Item>
                  <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true }]}>
                    <Select placeholder="Pilih Role Pengguna" size="large">
                      {dataRole.role.map((item) => (
                        <Option value={item.id_role} key={item.id_role}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitLoading}>
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane tab="Ganti Password" key="2">
                <Form
                  form={form}
                  layout={'vertical'}
                  onFinish={onFinish}
                  validateMessages={validateMessages}>
                  <Form.Item
                    label="Password Lama"
                    name="old_password"
                    rules={[
                      {
                        required: true,
                        min: 8,
                        message: 'Minimal 8 Karakter',
                      },
                    ]}>
                    <Input.Password placeholder="Password Lama" size="large" />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        min: 8,
                        message: 'Minimal 8 Karakter',
                      },
                    ]}>
                    <Input.Password placeholder="Password" size="large" />
                  </Form.Item>
                  <Form.Item
                    label="Konfirmasi Password"
                    name="password_confirmation"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        min: 8,
                        message: 'Minimal 8 Karakter',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve()
                          }

                          return Promise.reject(
                            new Error(
                              'Password yang anda masukkan tidak sesuai'
                            )
                          )
                        },
                      }),
                    ]}>
                    <Input.Password
                      placeholder="Konfirmasi Password"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitLoading}>
                      Ubah Password
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          )}
        </div>
      </div>
    </FetchLoading>
  )
}

export default PenggunaFormEdit
