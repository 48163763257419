import { Card, message, Tag } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FetchLoading } from '../../../components'
import Layout from '../../../layout'
import { removeCurrentUser } from '../../../redux/user/user.action'
import { selectCurrentUser } from '../../../redux/user/user.selectors'

const DetailTransaksi = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)
  const [transactions, setTransactions] = useState(null)
  // const [detailTransactions, setDetailTransactions] = useState(null)
  // const [detailPeserta, setDetailPeserta] = useState(null)

  const { slug } = useParams()

  useEffect(() => {
    axios({
      method: 'get',
      url: `/transaction/detail/${slug}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setTransactions(data)
        console.log(data)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
  }, [currentUser.token, dispatch, navigate, slug])

  return (
    <Layout id="detail-transaki" title="Detail Transaksi">
      <FetchLoading data={transactions}>
        <Card title="Peserta">
          <p>Nama : {transactions?.user?.name}</p>
          <p>Email : {transactions?.user?.email}</p>
          <p>No Telp : {transactions?.user?.phone}</p>
          <p>Status : {transactions?.user?.status}</p>
          {transactions?.user?.status === 'umum' ||
          transactions?.user?.status === null ? null : transactions?.user
              ?.status === 'bekerja' ? (
            <>
              <p>Perusahaan : {transactions?.user?.status_detail?.company}</p>
              <p>Jabatan : {transactions?.user?.status_detail?.position}</p>
            </>
          ) : (
            <>
              <p>
                Universitas : {transactions?.user?.status_detail?.university}
              </p>
              <p>Jurusan : {transactions?.user?.status_detail?.major}</p>
              <p>Angkatan : {transactions?.user?.status_detail?.class_year}</p>
            </>
          )}
        </Card>
        <br />
        <Card title="Pelatihan Yang Dibeli">
          {transactions?.detail_transaction.map((item) => (
            <ul>
              <li>{item.product.name}</li>
            </ul>
          ))}
          <p>
            Total Pembayaran :{' '}
            {transactions?.amount.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
          </p>
          <p>Metode Pembayaran : {transactions?.payment_channel}</p>
          <p>
            Status :{' '}
            <Tag
              color={
                transactions?.status === 'failed'
                  ? 'red'
                  : transactions?.status === 'pending'
                  ? 'orange'
                  : 'green'
              }>
              {transactions?.status.toUpperCase()}
            </Tag>
          </p>
        </Card>
      </FetchLoading>
    </Layout>
  )
}

export default DetailTransaksi
