import { useState } from 'react'
import { Button } from 'antd'
import axios from 'axios'

// REDUX
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selectors'

// COMPONENT
import { message, Modal } from 'antd'

import './ButtonUnblock.scss'
const ButtonUnblock = ({
  endPoints = '',
  id = null,
  msgFail = 'Data Gagal Diunblock',
  title = 'Apakah kamu yakin untuk membuka blokir data ini ?',
}) => {
  const currentUser = useSelector(selectCurrentUser)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const showModal = () => setOpen(true)

  const handleCancel = () => setOpen(false)

  const handleOk = () => {
    setLoading(true)
    axios({
      method: 'patch',
      url: `${endPoints}/${id}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        console.log(error)
        message.error(msgFail)
        setLoading(false)
      })
  }
  return (
    <>
      <Button className="ant-btn-green" onClick={showModal}>
        UnBlock
      </Button>
      <Modal
        className="ant-modal-confirm"
        closable={false}
        width={400}
        centered
        visible={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        loading={loading}>
        <p>{title}</p>
      </Modal>
    </>
  )
}

export default ButtonUnblock
