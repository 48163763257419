import Layout from '../../../layout'
import { FaqForm } from '../components'

const FaqTambah = () => {
  return (
    <Layout id="faq-tambah">
      <FaqForm title="Tambah FAQ" />
    </Layout>
  )
}

export default FaqTambah
