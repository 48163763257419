import React, { useState } from 'react'
import styles from './Sidebar.module.css'

import { useSelector, useDispatch } from 'react-redux'
import { removeCurrentUser } from '../../redux/user/user.action'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/logo/mindoDashboard.svg'

import { Button, message } from 'antd'
import MenuItems from './MenuItems'
import axios from 'axios'

import './Sidebar.scss'

message.config({
  maxCount: 1,
})

const Sidebar = () => {
  const currentUser = useSelector(selectCurrentUser)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onLogout = () => {
    setLoading(true)
    axios({
      method: 'post',
      url: '/logout',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(() => {
        dispatch(removeCurrentUser())
        message.success('Logout')
        navigate('/login')
      })
      .catch((error) => {})
  }
  return (
    <>
      <div id="sidebar" className={styles.container}>
        <div className={styles.logo}>
          <img src={logo} alt="mindo logo" />
          <h1>{currentUser.user.name}</h1>
          <h2>{`@${currentUser.user.username}`}</h2>
          <h2>{`Role : ${currentUser.user.role}`}</h2>
        </div>
        <div className={styles.inner}>
          <MenuItems />
          <div className="sidebar-container">
            <Button onClick={onLogout} block loading={loading}>
              Logout
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
