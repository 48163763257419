import Layout from "../../../layout"
import { TestimonialForm } from "../components"

const TestimoniEdit = () => {
  return (
    <Layout id="testimoni-edit">
      <TestimonialForm title="Edit Testimoni" />
    </Layout>
  )
}

export default TestimoniEdit
