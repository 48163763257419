import Layout from '../../../layout'
import { PenggunaForm } from '../components'

const PenggunaTambah = () => {
  return (
    <Layout id="pengguna-tambah">
      <PenggunaForm title="Tambah Pengguna" />
    </Layout>
  )
}

export default PenggunaTambah
