import Layout from '../../../layout'
import { FasilitasForm } from '../components'

const FasilitasEdit = () => {
  return (
    <Layout id="fasilitas-edit">
      <FasilitasForm title="Edit Fasilitas" />
    </Layout>
  )
}

export default FasilitasEdit
