import { Table, Space } from 'antd'
import { Link } from 'react-router-dom'
import ButtonDelete from '../../../../components/ButtonDelete'

const columns = (user_read_only) => {
  const column = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
  ]

  // eslint-disable-next-line no-unused-expressions
  user_read_only
    ? null
    : column.push({
        title: 'Action',
        key: 'action',
        render: (record) => (
          <Space size="middle" key={record.id}>
            <Link
              className="btn btn-tertiary"
              to={`/program/trainer/trainer-edit/${record.id}`}>
              Edit
            </Link>
            <ButtonDelete
              endPoints="/trainer"
              id={record.id}
              msgSuccess="Trainer Berhasil di Delete"
            />
          </Space>
        ),
      })

  return column
}

const TabelTrainer = ({ trainers, user_read_only }) => {
  return (
    <div>
      <Table
        bordered
        rowKey={(obj) => obj.id}
        columns={columns(user_read_only)}
        dataSource={trainers}
        pagination={{ pageSize: 5, position: ['', 'bottomCenter'] }}
      />
    </div>
  )
}

export default TabelTrainer
