import { Table, Space } from 'antd'
import { Link } from 'react-router-dom'

const columns = (user_read_only) => {
  const column = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Term Condition',
      dataIndex: 'term_condition',
      key: 'term_condition',
    },
  ]

  // eslint-disable-next-line no-unused-expressions
  user_read_only
    ? null
    : column.push({
        title: 'Action',
        key: 'action',
        render: (record) => (
          <Space size="middle" key={record.id}>
            <Link
              className="btn btn-tertiary"
              to={`/program/type/type-edit/${record.id}`}>
              Edit
            </Link>
          </Space>
        ),
      })

  return column
}

const TabelType = ({ type, user_read_only }) => {
  return (
    <div>
      <Table
        bordered
        rowKey={(obj) => obj.id}
        columns={columns(user_read_only)}
        dataSource={type}
        pagination={{ pageSize: 5, position: ['', 'bottomCenter'] }}
      />
    </div>
  )
}

export default TabelType
