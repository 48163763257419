import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../../../layout'
import { ProgramFormEdit } from '../components'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../redux/user/user.selectors'
import { removeCurrentUser } from '../../../redux/user/user.action'
import { message } from 'antd'

const ProgramEdit = () => {
  const [programData, setProgramData] = useState(null)
  const { slug } = useParams()
  const currentUser = useSelector(selectCurrentUser)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  message.config({
    maxCount: 1,
  })

  useEffect(() => {
    axios({
      method: 'get',
      url: `/product/edit/${slug}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setProgramData(data)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
  }, [currentUser.token, dispatch, navigate, slug])

  return (
    <Layout id="program-edit">
      <ProgramFormEdit initData={programData} title="Edit Program" />
    </Layout>
  )
}

export default ProgramEdit
