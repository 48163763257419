import Layout from '../../layout'
import { useEffect, useState } from 'react'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import { removeCurrentUser } from '../../redux/user/user.action'

import FetchLoading from '../../components/FetchLoading'
import axios from 'axios'
import { Button, DatePicker, message, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import TabelTransaksi from './components/Table'
import { SearchData } from '../../components'
import styles from './Transaksi.module.css'

// const XLSX = require('xlsx')
const fileDownload = require('js-file-download')

const Transaksi = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)
  const [transactions, setTransactions] = useState(null)
  const [searchField, setSearchField] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [mount, setMount] = useState('')
  const [year, setYear] = useState('')

  useEffect(() => {
    axios({
      method: 'get',
      url: '/transaction',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setTransactions(data)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
  }, [currentUser.token, dispatch, navigate])

  // const CSVToArray = (strData, strDelimiter) => {
  //   strDelimiter = strDelimiter || ','

  //   var objPattern = new RegExp(
  //     '(\\' +
  //       strDelimiter +
  //       '|\\r?\\n|\\r|^)' +
  //       '(?:"([^"]*(?:""[^"]*)*)"|' +
  //       '([^"\\' +
  //       strDelimiter +
  //       '\\r\\n]*))',
  //     'gi'
  //   )

  //   var arrData = [[]]

  //   var arrMatches = null

  //   while ((arrMatches = objPattern.exec(strData))) {
  //     var strMatchedDelimiter = arrMatches[1]
  //     if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
  //       arrData.push([])
  //     }

  //     var strMatchedValue
  //     if (arrMatches[2]) {
  //       strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"')
  //     } else {
  //       strMatchedValue = arrMatches[3]
  //     }
  //     arrData[arrData.length - 1].push(strMatchedValue)
  //   }

  //   return arrData
  // }

  const handleFilterSearch = (datas) => {
    return datas.filter((data) =>
      data.detail_transaction?.product.name
        .toLowerCase()
        .includes(searchField.toLowerCase())
    )
  }
  const handleFilteringProgram = () => {
    let transaksiFilter = transactions
    if (searchField.length) {
      transaksiFilter = handleFilterSearch(transaksiFilter)
    }
    return transaksiFilter
  }

  const transaksiFilter =
    transactions !== null ? handleFilteringProgram() : transactions

  const handleFilterSearchOption = () => {
    let searchOption = transactions
    searchOption = searchOption.map((transactions) => {
      return {
        value: transactions.detail_transaction?.product?.name,
        label: transactions.detail_transaction?.product?.name,
      }
    })
    return searchOption
  }

  const searchOptionFilter = transactions ? handleFilterSearchOption() : null

  return (
    <Layout id="transaction" title="Transaksi">
      <FetchLoading data={transactions}>
        <div className={styles.filterGroup}>
          <SearchData
            setSearchField={setSearchField}
            optionSearchField={searchOptionFilter}
          />
          <Button onClick={() => setModalOpen(true)}>Export Data</Button>
        </div>
        <TabelTransaksi transactions={transaksiFilter}></TabelTransaksi>
      </FetchLoading>
      <Modal
        title="Export Data Transaksi"
        centered
        footer={false}
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}>
        <DatePicker
          onChange={(dateString) => {
            setMount(dateString.format('M'))
            setYear(dateString.format('YYYY'))
          }}
          picker="month"
          size="large"
          style={{ width: '100%' }}
        />
        <div className={styles.buttonExport}>
          <Button
            style={{ width: '100%' }}
            onClick={() =>
              axios({
                method: 'get',
                url: `/transaction/download?month=${mount}&year=${year}`,
                headers: {
                  Authorization: `Bearer ${currentUser.token}`,
                },
              })
                .then((response) => {
                  // let wb = XLSX.utils.book_new()
                  // let ws = XLSX.utils.json_to_sheet(
                  // CSVToArray(response.data, ',')
                  // )
                  // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
                  // XLSX.writeFile(wb, 'Data Transaksi.xlsx')
                  fileDownload(response.data, `transaksi-${mount}-${year}.csv`)
                })
                .catch((error) => {
                  console.log(error)
                })
            }>
            Export
          </Button>
        </div>
      </Modal>
    </Layout>
  )
}

export default Transaksi
