import './main.scss'
import React from 'react'
import HeroImage from './assets/images/hero404.png'
import ButtonLink from '../../components/ButtonLink'

const NoMatch = (props) => {
  return (
    <div id="no-match-page">
      <div className="container">
        <div className="hero-group">
          <img src={HeroImage} alt="hero" />
        </div>
        <div className="btn-group">
          <ButtonLink
            title="Kembali Ke Beranda"
            urlRoute="/program"
            variant="primary"
          />
        </div>
      </div>
    </div>
  )
}

export default NoMatch
