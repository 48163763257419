import { Space, Table, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { ButtonDelete } from '../../../../components'
import ButtonCancel from '../../../../components/ButtonCancel'
const columns = [
  {
    title: 'ID Peserta',
    dataIndex: 'user_id',
    key: 'user_id',
  },
  {
    title: 'Nama',
    dataIndex: 'user',
    key: 'user',
    render: (user) => user.name,
  },
  {
    title: 'Pelatihan',
    dataIndex: 'detail_transaction',
    key: 'detail_transaction',

    render: (detail_transaction) => detail_transaction?.product?.name,
  },
  {
    title: 'No VA',
    dataIndex: 'external_id',
    key: 'external_id',
  },
  {
    title: 'Total Pelatihan',
    dataIndex: 'count_product',
    key: 'count_product',
    render: (count_product) => (
      <Tag color={count_product === 1 ? '#108ee9' : '#f50'}>
        {count_product}
      </Tag>
    ),
  },
  {
    title: 'Total Pembayaran',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount) => `Rp ${amount.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`,
  },
  {
    title: 'Dibuat',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (created_at) =>
      created_at.slice(0, 10).split('-').reverse().join('-'),
    width: '15%',
  },
  {
    title: 'Berakhir',
    dataIndex: 'expiration_date',
    key: 'expiration_date',
    render: (expiration_date) =>
      expiration_date
        ? expiration_date.slice(0, 10).split('-').reverse().join('-')
        : '-',
    width: '15%',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => (
      <Tag
        color={
          status === 'failed'
            ? 'red'
            : status === 'pending'
            ? 'orange'
            : 'green'
        }>
        {status.toUpperCase()}
      </Tag>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (record) => (
      <Space size="middle" key={record.id}>
        <Link
          className="btn btn-primary"
          to={`/transaksi/${record.transaction_id}`}>
          Detail
        </Link>
        {record.status === 'pending' ? (
          <ButtonCancel
            endPoints="/transaction/cancel-admin-transaction"
            id={record.transaction_id}
            msgSuccess="Transaksi Berhasil di Batalkan"
          />
        ) : record.status === 'failed' ? (
          <ButtonDelete
            endPoints="/transaction"
            id={record.transaction_id}
            msgSuccess="Transaksi Berhasil di Delete"
          />
        ) : null}
      </Space>
    ),
  },
]

const TabelTransaksi = ({ transactions }) => {
  console.log(transactions)
  return (
    <div>
      <Table
        bordered
        rowKey={(obj) => obj.id}
        columns={columns}
        dataSource={transactions}
        pagination={{ pageSize: 5, position: ['', 'bottomCenter'] }}
      />
    </div>
  )
}

export default TabelTransaksi
