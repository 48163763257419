import React from 'react'
import styles from './MenuItems.module.css'
import './MenuItems.scss'
import { Menu } from 'antd'

// REDUX
import { useSelector } from 'react-redux'

import { useLocation, Link } from 'react-router-dom'
import { selectCurrentUser } from '../../../redux/user/user.selectors'
import {
  ROLE_FAQ_FALSE,
  ROLE_PENGGUNA_FALSE,
  ROLE_PRODUCT_READ_ONLY,
  ROLE_TESTIMONI_FALSE,
} from '../../../utils'
const { SubMenu } = Menu

const MenuItems = () => {
  const currentUser = useSelector(selectCurrentUser)
  const location = useLocation()

  const user_read_only = ROLE_PRODUCT_READ_ONLY.find(
    (role) => role === currentUser.user.role
  )

  const user_pengguna_false = ROLE_PENGGUNA_FALSE.find(
    (role) => role === currentUser.user.role
  )

  const user_faq_false = ROLE_FAQ_FALSE.find(
    (role) => role === currentUser.user.role
  )

  const user_testimoni_false = ROLE_TESTIMONI_FALSE.find(
    (role) => role === currentUser.user.role
  )

  return (
    <div id="sidebar-menu">
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        defaultOpenKeys={
          '/' === location.pathname
            ? undefined
            : '/faq' === location.pathname
            ? undefined
            : '/testimoni' === location.pathname
            ? undefined
            : '/transaksi' === location.pathname
            ? undefined
            : '/pengguna' === location.pathname
            ? undefined
            : '/peserta' === location.pathname
            ? undefined
            : '/profile' === location.pathname
            ? undefined
            : ['programSubmenu']
        }
        className={styles.sidebar}>
        <SubMenu key="programSubmenu" title="Program Menu">
          <Menu.Item key="/program">
            <Link to={'/program'}>Program</Link>
          </Menu.Item>
          <Menu.Item key="/program/kategori">
            <Link to={'/program/kategori'}>Kategori</Link>
          </Menu.Item>
          <Menu.Item key="/program/fasilitas">
            <Link to={'/program/fasilitas'}>Fasilitas</Link>
          </Menu.Item>
          <Menu.Item key="/program/type">
            <Link to={'/program/type'}>Type Program</Link>
          </Menu.Item>
          {user_read_only ? null : (
            <Menu.Item key="/program/kupon">
              <Link to={'/program/kupon'}>Kupon</Link>
            </Menu.Item>
          )}
          <Menu.Item key="/program/trainer">
            <Link to={'/program/trainer'}>Trainer</Link>
          </Menu.Item>
        </SubMenu>
        {user_faq_false ? null : (
          <Menu.Item key="/faq">
            <Link to={'/faq'}>FAQ</Link>
          </Menu.Item>
        )}
        {user_testimoni_false ? null : (
          <Menu.Item key="/testimoni">
            <Link to={'/testimoni'}>Testimoni</Link>
          </Menu.Item>
        )}
        <Menu.Item key="/transaksi">
          <Link to={'/transaksi'}>Transaksi</Link>
        </Menu.Item>
        {user_pengguna_false ? null : (
          <Menu.Item key="/pengguna">
            <Link to={'/pengguna'}>Pengguna</Link>
          </Menu.Item>
        )}
        <Menu.Item key="/peserta">
          <Link to={'/peserta'}>Peserta</Link>
        </Menu.Item>
        <Menu.Item key="/profile">
          <Link to={'/profile'}>Profile</Link>
        </Menu.Item>
      </Menu>
    </div>
  )
}

export default MenuItems
