import { Table, Space, Tag } from 'antd'
import { Link } from 'react-router-dom'
import ButtonBlock from '../../../../components/ButtonBlock'
import ButtonDelete from '../../../../components/ButtonDelete'
import ButtonUnblock from '../../../../components/ButtonUnblock'

const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'company',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: '30%',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (record) => (
      <Tag color="#213A5E" key={record}>
        {record}
      </Tag>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (record) => (
      <Space size="middle" key={record.id}>
        <Link
          className="btn btn-tertiary"
          to={`/pengguna/pengguna-edit/${record.id}`}>
          Edit
        </Link>
        <ButtonDelete
          endPoints="/user"
          id={record.id}
          msgSuccess="Pengguna Berhasil di Delete"
        />
        {record.blocked === '0' ? (
          <ButtonBlock
            endPoints="/user/block"
            id={record.id}
            msgSuccess="Pengguna Berhasil di Blokir"
          />
        ) : (
          <ButtonUnblock
            endPoints="/user/unblock"
            id={record.id}
            msgSuccess="Pengguna Berhasil Membuka Blokir"
          />
        )}
      </Space>
    ),
  },
]

const TabelPengguna = ({ users }) => {
  return (
    <div>
      <Table
        bordered
        rowKey={(obj) => obj.id}
        columns={columns}
        dataSource={users}
        pagination={{ pageSize: 5, position: ['', 'bottomCenter'] }}
      />
    </div>
  )
}

export default TabelPengguna
