import { Button, Form, Input, message, Rate, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './Testimonial.module.css'
// import FormUpload from "../../../../components/FormUpload"
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import { FetchLoading } from '../../../../components'
import { removeCurrentUser } from '../../../../redux/user/user.action'

message.config({
  maxCount: 1,
})

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

const TestimonialForm = ({ title = '' }) => {
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const [file, setFile] = useState(null)
  const [testimoniData, setTestimoniData] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const { slug } = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const [form] = Form.useForm()

  const validateUpload = () => {
    if (slug) {
      return [
        {
          required: false,
        },
      ]
    } else {
      return [
        {
          required: true,
          message: 'Foto belum di isi!',
        },
      ]
    }
  }

  useEffect(() => {
    if (slug) {
      axios({
        method: 'get',
        url: `/testimonial/${slug}`,
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
        .then(({ data }) => {
          setTestimoniData(data)
          setLoading(false)
        })
        .catch(function (error) {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
        })
    } else {
      setLoading(false)
      setTestimoniData({
        name: '',
        company: '',
        message: '',
        rating: 0,
        foto: file,
      })
    }
  }, [currentUser.token, dispatch, file, navigate, slug])

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setStatus(true)
      return
    }
    if (info.file.status === 'done') {
      setFile(info.file.originFileObj)
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImageUrl(imageUrl),
        setStatus(false)
      )
    }
  }

  const uploadButton = (
    <div style={{ margin: 50 }}>
      {status ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const handleRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const onFinish = (values) => {
    setSubmitLoading(true)
    const { name, company, pesan, rating } = values
    var data = new FormData()
    data.append('name', name)
    data.append('company', company)
    data.append('message', pesan)
    data.append('rating', rating)
    if (file != null) {
      data.append('foto', file)
    }
    if (slug) {
      axios({
        method: 'post',
        url: `/testimonial/update/${slug}`,
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then(() => {
          setSubmitLoading(false)
          form.resetFields()
          message.success('Testimoni Berhasil DiUpdate')
          navigate('/testimoni')
        })
        .catch((error) => {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
          setSubmitLoading(false)
        })
    } else {
      axios({
        method: 'post',
        url: '/testimonial',
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
        data: data,
      })
        .then(() => {
          form.resetFields()
          message.success('Testimoni Berhasil Ditambah')
          navigate('/testimoni')
        })
        .catch((error) => {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
        })
    }
  }

  return (
    <FetchLoading data={testimoniData}>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1 className={styles.title}>{title}</h1>
          {!loading && (
            <Form
              form={form}
              layout={'vertical'}
              onFinish={onFinish}
              initialValues={{
                name: testimoniData.name,
                company: testimoniData.company,
                pesan: testimoniData.message,
                rating: testimoniData.rating,
              }}>
              <Form.Item
                label="Nama"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Nama belum di isi!',
                  },
                ]}>
                <Input placeholder="input placeholder" size="large" />
              </Form.Item>
              <Form.Item
                label="Company"
                name="company"
                rules={[
                  {
                    required: true,
                    message: 'Company belum di isi!',
                  },
                ]}>
                <Input placeholder="input placeholder" size="large" />
              </Form.Item>
              <Form.Item
                label="Pesan"
                name="pesan"
                rules={[
                  {
                    required: true,
                    message: 'Message belum di isi!',
                  },
                ]}>
                <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
              </Form.Item>
              <Form.Item label="Rating" name="rating">
                <Rate allowHalf />
              </Form.Item>
              <Form.Item
                label="Upload Photo"
                name="foto"
                extra="Image (JPG/PNG/JPEG)"
                rules={validateUpload()}>
                <Upload
                  accept="image/png, image/jpeg, image/jpg"
                  style={{ width: '100%' }}
                  name="foto"
                  listType="picture-card"
                  className="avatar-uploader"
                  customRequest={handleRequest}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}>
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="cover"
                      style={{ maxHeight: '300px' }}
                    />
                  ) : testimoniData.foto ? (
                    <img
                      src={`https://api.mindoeducation.co.id/storage/testimonial/${testimoniData.foto}`}
                      alt="cover"
                      style={{ maxHeight: '300px' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </FetchLoading>
  )
}

export default TestimonialForm
