import { Button, Form, Input, message, Tabs } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styles from './ProfileForm.module.css'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import { FetchLoading } from '../../../../components'
import { removeCurrentUser } from '../../../../redux/user/user.action'

const { TabPane } = Tabs

message.config({
  maxCount: 1,
})

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} harus diisi!',
}
/* eslint-enable no-template-curly-in-string */

const ProfileForm = ({ title = '' }) => {
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [profileData, setProfileData] = useState(null)
  const [tabKey, setTabKey] = useState('1')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)
  const [form] = Form.useForm()

  useEffect(() => {
    axios({
      method: 'get',
      url: '/profile-admin',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setProfileData(data)
        setLoading(false)
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          error.response.data.message
            ? message.error(error.response.data.message)
            : message.error('403 Forbidden')
        }
        setSubmitLoading(false)
      })
  }, [currentUser.token, dispatch, navigate])

  const onFinish = (values) => {
    setSubmitLoading(true)
    const {
      name,
      username,
      email,
      city,
      address,
      phone,
      zip_code,
      old_password,
      password,
      password_confirmation,
    } = values

    switch (tabKey) {
      case '1':
        const dataProfile = JSON.stringify({
          name,
          username,
          email,
          city,
          address,
          phone,
          zip_code,
        })
        axios({
          method: 'patch',
          url: '/profile-admin',
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
            'Content-Type': 'application/json',
          },
          data: dataProfile,
        })
          .then(() => {
            message.success('Profile Berhasil DiUpdate')
            navigate('/profile')
            setSubmitLoading(false)
          })
          .catch((error) => {
            if (error.response.status === 404) {
              message.error('404 Not Found')
            }
            if (error.response.status === 401) {
              message.error('401 Unauthorized')
              dispatch(removeCurrentUser())
              navigate('/login')
            }
            if (error.response.status === 500) {
              message.error('500 Internal Server Error')
            }
            if (error.response.status === 403) {
              message.error('403 Forbidden')
            }
          })
        break

      case '2':
        const dataPassword = JSON.stringify({
          old_password,
          password,
          password_confirmation,
        })

        axios({
          method: 'patch',
          url: '/change-password',
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
            'Content-Type': 'application/json',
          },
          data: dataPassword,
        })
          .then(() => {
            message.success('Password Berhasil DiUpdate')
            navigate('/profile')
            setSubmitLoading(false)
          })
          .catch((error) => {
            if (error.response.status === 404) {
              message.error('404 Not Found')
            }
            if (error.response.status === 401) {
              message.error('401 Unauthorized')
              dispatch(removeCurrentUser())
              navigate('/login')
            }
            if (error.response.status === 500) {
              message.error('500 Internal Server Error')
            }
            if (error.response.status === 403) {
              message.error('403 Forbidden')
            }
          })
        break

      default:
        break
    }
  }

  return (
    <FetchLoading data={profileData}>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1 className={styles.title}>{title}</h1>
          {!loading && (
            <Tabs defaultActiveKey="1" onTabClick={(key) => setTabKey(key)}>
              <TabPane tab="Profile" key="1">
                {tabKey === '1' ? (
                  <Form
                    form={form}
                    layout={'vertical'}
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    initialValues={{
                      name: profileData.name,
                      username: profileData.username,
                      email: profileData.email,
                      city: profileData.city,
                      address: profileData.address,
                      phone: profileData.phone,
                      zip_code: profileData.zip_code,
                    }}>
                    <Form.Item
                      label="Nama"
                      name="name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}>
                      <Input placeholder="Nama" size="large" />
                    </Form.Item>
                    <Form.Item
                      label="Username"
                      name="username"
                      rules={[
                        {
                          required: true,
                        },
                      ]}>
                      <Input placeholder="Username" size="large" />
                    </Form.Item>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          message: 'Email Tidak Valid',
                        },
                        {
                          required: true,
                        },
                      ]}>
                      <Input placeholder="Email" size="large" />
                    </Form.Item>
                    <Form.Item
                      label="Kota"
                      name="city"
                      rules={[
                        {
                          required: true,
                        },
                      ]}>
                      <Input placeholder="Kota" size="large" />
                    </Form.Item>
                    <Form.Item
                      label="Alamat"
                      name="address"
                      rules={[
                        {
                          required: true,
                        },
                      ]}>
                      <Input placeholder="Alamat" size="large" />
                    </Form.Item>
                    <Form.Item
                      label="Nomor Telepon"
                      name="phone"
                      rules={[
                        {
                          required: true,
                        },
                      ]}>
                      <Input placeholder="Nomor Telepon" size="large" />
                    </Form.Item>
                    <Form.Item
                      label="Kode Pos"
                      name="zip_code"
                      rules={[
                        {
                          required: true,
                        },
                      ]}>
                      <Input placeholder="Kode Pos" size="large" />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={submitLoading}>
                        Simpan Perubahan
                      </Button>
                      <Button
                        type="primary"
                        htmlType="button"
                        onClick={() => form.resetFields()}
                        style={{ marginLeft: '10px' }}>
                        Reset
                      </Button>
                    </Form.Item>
                  </Form>
                ) : null}
              </TabPane>
              <TabPane tab="Ganti Password" key="2">
                {tabKey === '2' ? (
                  <Form
                    form={form}
                    layout={'vertical'}
                    onFinish={onFinish}
                    validateMessages={validateMessages}>
                    <Form.Item
                      label="Password Lama"
                      name="old_password"
                      rules={[
                        {
                          required: true,
                          min: 8,
                          message: 'Minimal 8 Karakter',
                        },
                      ]}>
                      <Input.Password
                        placeholder="Password Lama"
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Password Baru"
                      name="password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          min: 8,
                          message: 'Minimal 8 Karakter',
                        },
                      ]}>
                      <Input.Password
                        placeholder="Password Baru"
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Konfirmasi Password Baru"
                      name="password_confirmation"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          min: 8,
                          message: 'Minimal 8 Karakter',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }

                            return Promise.reject(
                              new Error(
                                'Password yang anda masukkan tidak sesuai'
                              )
                            )
                          },
                        }),
                      ]}>
                      <Input.Password
                        placeholder="Konfirmasi Password Baru"
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={submitLoading}>
                        Ubah Password
                      </Button>
                    </Form.Item>
                  </Form>
                ) : null}
              </TabPane>
            </Tabs>
          )}
        </div>
      </div>
    </FetchLoading>
  )
}

export default ProfileForm
