import { Button, Form, Input, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styles from './TypeForm.module.css'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import { removeCurrentUser } from '../../../../redux/user/user.action'

import { FetchLoading } from '../../../../components'

message.config({
  maxCount: 1,
})

const TypeForm = ({ title = '' }) => {
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [typeData, setTypeData] = useState(null)
  const { slug } = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)
  const [form] = Form.useForm()

  useEffect(() => {
    axios({
      method: 'get',
      url: `/type-product/${slug}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setTypeData(data)
        setLoading(false)
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
  }, [currentUser.token, dispatch, navigate, slug])

  const onFinish = (values) => {
    setSubmitLoading(true)
    const { type, term_condition } = values
    const data = JSON.stringify({
      type,
      term_condition,
    })
    axios({
      method: 'patch',
      url: `/type-product/${slug}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(() => {
        form.resetFields()
        message.success('Type Berhasil DiUpdate')
        navigate('/program/type')
      })
      .catch((error) => {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
  }

  return (
    <FetchLoading data={typeData}>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1 className={styles.title}>{title}</h1>
          {!loading && (
            <Form
              form={form}
              layout={'vertical'}
              onFinish={onFinish}
              initialValues={{
                type: typeData.type,
                term_condition: typeData.term_condition,
              }}>
              <Form.Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Type belum di isi!',
                  },
                ]}>
                <Input placeholder="Tipe Produk" size="large" />
              </Form.Item>
              <Form.Item
                label="Term Condition"
                name="term_condition"
                rules={[
                  {
                    required: true,
                    message: 'Term Condition belum di isi!',
                  },
                ]}>
                <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </FetchLoading>
  )
}

export default TypeForm
