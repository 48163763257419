import Layout from "../../../layout"
import { KategoriForm } from "../components"

const KategoriEdit = () => {
  return (
    <Layout id="kategori-edit">
      <KategoriForm title="Edit Kategori" />
    </Layout>
  )
}

export default KategoriEdit
