import React from 'react'
import { Input, AutoComplete } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import './SearchData.scss'
import { useLocation } from 'react-router-dom'

const SearchData = ({ optionSearchField, setSearchField, block = false }) => {
  const location = useLocation()
  const handleSearch = (value) => {
    setSearchField(value)
  }

  return (
    <div className={`input-search-data ${block && 'input-search-data--block'}`}>
      {location.pathname === '/transaksi' ? (
        <AutoComplete
          // options={optionSearchField}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onChange={handleSearch}
          // notFoundContent="Pencarian Tidak Ditemukan"
        >
          <Input
            allowClear
            bordered={false}
            size="large"
            placeholder="Cari Data Pelatihan"
            prefix={<SearchOutlined />}
            required
          />
        </AutoComplete>
      ) : (
        <AutoComplete
          options={optionSearchField}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onChange={handleSearch}
          notFoundContent="Pencarian Tidak Ditemukan">
          <Input
            allowClear
            bordered={false}
            size="large"
            placeholder="Cari Data"
            prefix={<SearchOutlined />}
            required
          />
        </AutoComplete>
      )}
    </div>
  )
}

export default SearchData
