import { Button, Form, Input, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styles from './TrainerForm.module.css'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import { removeCurrentUser } from '../../../../redux/user/user.action'

import { FetchLoading } from '../../../../components'

message.config({
  maxCount: 1,
})

const TrainerForm = ({ title = '' }) => {
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [trainerData, setTrainerData] = useState(null)
  const { slug } = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const [form] = Form.useForm()

  useEffect(() => {
    if (slug) {
      axios({
        method: 'get',
        url: `/trainer/${slug}`,
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
        .then(({ data }) => {
          setTrainerData(data)
          setLoading(false)
        })
        .catch((error) => {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
        })
    } else {
      setLoading(false)
      setTrainerData({
        name: '',
      })
    }
  }, [currentUser.token, dispatch, navigate, slug])

  const onFinish = (values) => {
    setSubmitLoading(true)
    const { name } = values
    const data = JSON.stringify({
      name,
    })
    if (slug) {
      axios({
        method: 'patch',
        url: `/trainer/${slug}`,
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then(() => {
          form.resetFields()
          message.success('Trainer Berhasil DiUpdate')
          navigate('/program/trainer')
        })
        .catch((error) => {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
        })
    } else {
      axios({
        method: 'post',
        url: '/trainer',
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then(() => {
          form.resetFields()
          message.success('Trainer Berhasil Ditambah')
          navigate('/program/trainer')
        })
        .catch((error) => {
          if (error.response.status === 404) {
            message.error('404 Not Found')
          }
          if (error.response.status === 401) {
            message.error('401 Unauthorized')
            dispatch(removeCurrentUser())
            navigate('/login')
          }
          if (error.response.status === 500) {
            message.error('500 Internal Server Error')
          }
          if (error.response.status === 403) {
            message.error('403 Forbidden')
          }
        })
    }
  }

  return (
    <FetchLoading data={trainerData}>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1 className={styles.title}>{title}</h1>
          {!loading && (
            <Form
              form={form}
              layout={'vertical'}
              onFinish={onFinish}
              initialValues={{ name: trainerData.name }}>
              <Form.Item
                label="Nama Trainer"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input placeholder="Nama Trainer" size="large" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </FetchLoading>
  )
}

export default TrainerForm
