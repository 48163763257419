import React from 'react'
import Sidebar from '../components/Sidebar'
import styles from './Layout.module.css'
const Layout = ({ id = '', title = '', children }) => {
  return (
    <div id={`${id}`} style={{ minHeight: '100vh' }}>
      <Sidebar />
      <main className={styles.container}>
        {title.length ? <h1 className={styles.title}>{title}</h1> : null}
        {children}
      </main>
    </div>
  )
}

export default Layout
