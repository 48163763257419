import Layout from '../../../layout'
import { TypeForm } from '../components'

const TypeEdit = () => {
  return (
    <Layout id="type-edit">
      <TypeForm title="Edit Type" />
    </Layout>
  )
}

export default TypeEdit
