import { Table, Space, Tag } from 'antd'
import { Link } from 'react-router-dom'
import ButtonDelete from '../../../../components/ButtonDelete'

const columns = (user_read_only) => {
  const column = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Kode Kategori',
      dataIndex: 'code_category',
      key: 'code_category',
      render: (record) => (
        <Tag color="#213A5E" key={record}>
          {record}
        </Tag>
      ),
    },
  ]
  // eslint-disable-next-line no-unused-expressions
  user_read_only
    ? null
    : column.push({
        title: 'Action',
        key: 'action',
        render: (record) => (
          <Space size="middle" key={record.id}>
            <Link
              className="btn btn-tertiary"
              to={`/program/kategori/kategori-edit/${record.id}`}>
              Edit
            </Link>
            <ButtonDelete
              endPoints="/category"
              id={record.id}
              msgSuccess="Kategori Berhasil di Delete"
            />
          </Space>
        ),
      })

  return column
}

const TabelKagetori = ({ categories, user_read_only }) => {
  return (
    <div>
      <Table
        bordered
        rowKey={(obj) => obj.id}
        columns={columns(user_read_only)}
        dataSource={categories}
        pagination={{ pageSize: 5, position: ['', 'bottomCenter'] }}
      />
    </div>
  )
}

export default TabelKagetori
