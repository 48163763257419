import Layout from "../../../layout"
import { KategoriForm } from "../components"

const KategoriTambah = () => {
  return (
    <Layout id="kategori-tambah">
      <KategoriForm title="Tambah Kategori" />
    </Layout>
  )
}

export default KategoriTambah
