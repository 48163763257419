import { Table, Space } from 'antd'
import { Link } from 'react-router-dom'
import ButtonDelete from '../../../../components/ButtonDelete'
const columns = [
  {
    title: 'Question',
    dataIndex: 'question',
    key: 'question',
  },
  {
    title: 'Answer',
    dataIndex: 'answer',
    key: 'answer',
    width: '60%',
  },
  {
    title: 'Action',
    key: 'action',
    render: (record) => (
      <Space size="middle" key={record.id}>
        <Link className="btn btn-tertiary" to={`/faq/faq-edit/${record.id}`}>
          Edit
        </Link>
        <ButtonDelete
          endPoints="/faq"
          id={record.id}
          msgSuccess="FAQ Berhasil di Delete"
        />
      </Space>
    ),
  },
]

const TabelFaq = ({ faqs }) => {
  return (
    <div>
      <Table
        bordered
        rowKey={(obj) => obj.id}
        columns={columns}
        dataSource={faqs}
        pagination={{ pageSize: 5, position: ['', 'bottomCenter'] }}
      />
    </div>
  )
}

export default TabelFaq
