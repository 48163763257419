import Layout from "../../../layout"
import { TestimonialForm } from "../components"

const TestimonialTambah = () => {
  return (
    <Layout id="testimoni-tambah">
      <TestimonialForm title="Tambah Testimoni" />
    </Layout>
  )
}

export default TestimonialTambah
