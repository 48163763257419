import React, { useEffect, useState } from 'react'
import Layout from '../../layout'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import { removeCurrentUser } from '../../redux/user/user.action'

import FetchLoading from '../../components/FetchLoading'
import ButtonLink from '../../components/ButtonLink'
import axios from 'axios'
import TabelKagetori from './components/Table'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ROLE_PRODUCT_READ_ONLY } from '../../utils'

const PogramKategori = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)
  const [categories, setCategories] = useState(null)

  const user_read_only = ROLE_PRODUCT_READ_ONLY.find(
    (role) => role === currentUser.user.role
  )

  useEffect(() => {
    axios({
      method: 'get',
      url: '/category',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setCategories(data)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          message.error('404 Not Found')
        }
        if (error.response.status === 401) {
          message.error('401 Unauthorized')
          dispatch(removeCurrentUser())
          navigate('/login')
        }
        if (error.response.status === 500) {
          message.error('500 Internal Server Error')
        }
        if (error.response.status === 403) {
          message.error('403 Forbidden')
        }
      })
  }, [currentUser.token, dispatch, navigate])

  return (
    <Layout id="kategori" title="Kategori">
      {user_read_only ? null : (
        <ButtonLink
          urlRoute="/program/kategori/kategori-tambah"
          title="Tambah Kategori"
        />
      )}
      <FetchLoading data={categories}>
        <TabelKagetori
          categories={categories}
          user_read_only={user_read_only}></TabelKagetori>
      </FetchLoading>
    </Layout>
  )
}

export default PogramKategori
