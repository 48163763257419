import Layout from '../../../layout'
import { TrainerForm } from '../components'

const TrainerEdit = () => {
  return (
    <Layout id="trainer-edit">
      <TrainerForm title="Edit Trainer" />
    </Layout>
  )
}

export default TrainerEdit
