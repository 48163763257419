import Layout from '../../../layout'
import { ProgramForm } from '../components'

const ProgramTambah = () => {
  return (
    <Layout id="program-tambah">
      <ProgramForm title="Tambah Program" />
    </Layout>
  )
}

export default ProgramTambah
