import { ButtonDelete } from '../../../../components'
import { Tag, Space } from 'antd'
import { Link } from 'react-router-dom'

const columns = (user_read_only) => {
  const column = [
    {
      key: 'name',
      title: 'Nama Pelatihan',
      dataIndex: 'name',
    },
    {
      key: 'time_start',
      title: 'Tanggal Pelatihan',
      dataIndex: 'time_start',
      render: (time_start) =>
        time_start.slice(0, 10).split('-').reverse().join('-'),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status) =>
        status === 'active' ? (
          <Tag color="green">{status.toUpperCase()}</Tag>
        ) : (
          <Tag color="orange">{status.toUpperCase()}</Tag>
        ),
      width: '10%',
    },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      render: (category) => category.name,
      width: '10%',
    },
    {
      key: 'type',
      title: 'Type',
      dataIndex: 'type',
      width: '10%',
    },
  ]

  // eslint-disable-next-line no-unused-expressions
  user_read_only
    ? null
    : column.push({
        key: 'action',
        title: 'Action',
        render: (record) => {
          console.log(record)
          return (
            <Space size="middle" key={record.id}>
              {record.id === 13 ? (
                'No Action'
              ) : (
                <>
                  <Link
                    className="btn btn-tertiary"
                    to={`/program/program-edit/${record.id}`}>
                    Edit
                  </Link>
                  <Link
                    className="btn btn-tertiary"
                    to={`/program/program-duplikat/${record.id}`}>
                    Duplikat
                  </Link>
                  <ButtonDelete
                    endPoints="/product"
                    id={record.id}
                    msgSuccess="Product Berhasil di Delete"
                  />
                </>
              )}
            </Space>
          )
        },
        width: '10%',
      })

  return column
}

export default columns
