import { Table, Space } from 'antd'
import { Link } from 'react-router-dom'
import ButtonDelete from '../../../../components/ButtonDelete'

const columns = (user_read_only) => {
  const column = [
    {
      title: 'Nama Fasilitas',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon) => (
        <img
          src={`https://api.mindoeducation.co.id/storage/facility/${icon}`}
          alt="icon"
          style={{ width: 30 }}
        />
      ),
    },
  ]

  // eslint-disable-next-line no-unused-expressions
  user_read_only
    ? null
    : column.push({
        key: 'action',
        title: 'Action',
        render: (record) => (
          <Space size="middle" key={record.id}>
            <Link
              className="btn btn-tertiary"
              to={`/program/fasilitas/fasilitas-edit/${record.id}`}>
              Edit
            </Link>
            <ButtonDelete
              endPoints="/facility"
              id={record.id}
              msgSuccess="Fasiltias Berhasil di Delete"
            />
          </Space>
        ),
      })

  return column
}

const TabelFasilitas = ({ fasilitas, user_read_only }) => {
  console.log(fasilitas)
  return (
    <div>
      <Table
        bordered
        rowKey={(obj) => obj.id}
        columns={columns(user_read_only)}
        dataSource={fasilitas}
        pagination={{ pageSize: 5, position: ['', 'bottomCenter'] }}
      />
    </div>
  )
}

export default TabelFasilitas
